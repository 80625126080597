import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    customization: {},
    productPrice: 0,
    snackbar: {
      content: '',
      color: '',
      icon: '',
      isVisible: false,
    },
    selectedVendor: null,
  },
  mutations: {
    setCustomization(state, payload) {
      state.customization = { ...payload };
    },
    setProductPrice(state, price) {
      state.productPrice = price;
    },
    setSnackbar(state, payload) {
      state.snackbar = payload;
    },
    closeSnackbar(state) {
      state.snackbar.isVisible = false;
    },
    setSelectedVendor(state, vendor) {
      localStorage.setItem('selectedVendor', JSON.stringify(vendor._id));
    },
  },

  modules: {},
  getters: {
    getSnackbar: (state) => state.snackbar,
    getProductPrice: (state) => state.productPrice,
  },
});
