import Vue from 'vue';
import Vuetify from 'vuetify';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Toasted from 'vue-toasted';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'vuetify/dist/vuetify.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/plugins/filter.js';
import { SVG, extend as SVGextend, Element as SVGElement } from '@svgdotjs/svg.js';
let imgkit = process.env['VUE_APP_IMGKIT_URL'];

Vue.use(SVG);

Vue.use(Vuetify);
Vue.use(Vuelidate);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(Toasted);

Vue.config.productionTip = false;

// Disable console.log statements in production
if (process.env.VUE_APP_API_URL === "https://wl-api.palettier.com") {
  console.log = () => {};
}

Vue.mixin({
  methods: {
    generateCompressImgkitURL(url, w, h, ar) {
      const imgkitQuery = ar
        ? `?tr=w-${w},h-${h},q-90,lo-false,c-maintain_ratio,ar-${ar}`
        : `?tr=w-${w},h-${h},q-90,lo-false,c-maintain_ratio`;

      let finalUrl = '';
      if (url) {
        const lastUrl = this.getLastUrlFromUrl(url);
        const imgkitBaseUrl = this.checkIfGoogleDriveURL(url) ? imgkit : imgkit;
        finalUrl = imgkitBaseUrl + lastUrl;
      }
      finalUrl += imgkitQuery;
      return finalUrl;
    },
    getLastUrlFromUrl(url) {
      try {
        let lastUrl = url.split('.com').pop().split('?')[0];
        lastUrl = lastUrl.replace('/', '');

        return lastUrl;
      } catch (error) {
        console.error('Error extracting last URL from the provided URL:', error);
        return '';
      }
    },
    checkIfGoogleDriveURL(url) {
      return url.includes('palettier-google-drive');
    },
  },
});
new Vue({
  vuetify: new Vuetify(),
  router,
  SVG,
  store,
  render: (h) => h(App),
}).$mount('#app');
