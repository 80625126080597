<template>
  <div id="app">
    <v-app>
      <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
      <div v-if="$route.path != '/login'"><Header /></div>
      <snack-bar />
      <v-main class="mainClass">
        <v-container fluid>
          <v-row class="fill-height">
            <v-col>
              <transition name="fade">
                <router-view></router-view>
              </transition>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import SnackBar from "./components/SnackBar.vue";

export default {
  components: {
    Header,
    SnackBar,
  },
  created() {
    this.checkLogin();
  },
  watch: {
    $route() {
      this.checkLogin();
    },
  },
  methods: {
    checkLogin() {
      if (localStorage.getItem("accessToken") == null) {
        this.$router.replace("/login");
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/Scss/_main.scss";
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
.mainClass {
  padding-top: 0px !important;
}
</style>
