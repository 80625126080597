<template>
  <div align="center" class="mt-10">
    <div class="justify-center">
      <v-card width="490px" class="login-card">
        <div class="pt-16 font-30 butler-700">Login</div>
        <v-form ref="form" v-model="valid">
          <div class="mt-6 mx-6">
            <v-text-field
              v-model="form.email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
          </div>
          <div class="mt-6 mx-6">
            <v-text-field
              v-model="form.password"
              :rules="passwordRules"
              type="password"
              label="Password"
              hint="Minimum 8 Zeichen"
              counter
            ></v-text-field>
          </div>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mt-8 px-8 black-btn"
            @click="validate"
          >
            Login
          </v-btn>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import loginService from "@/services/loginService";

export default {
  components: {},
  data: () => ({
    valid: true,
    password: "",
    passwordRules: [
      (v) => !!v || "Password ist needed",
      (v) => v.length >= 8 || "Password is to short",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    form: {
      email: "",
      password: "",
      userType: "Admin",
    },
  }),

  methods: {
    async validate() {
      this.$refs.form.validate();
      if (this.valid === false) return;
      const data = {};
      data.email = this.form.email;
      data.password = this.form.password;
      data.userType = this.form.userType;
      try {
        const result = await loginService.login(data);
        if (result.status == 200) {
          localStorage.accessToken = result.data.accessToken;
          this.$router.push("/viewUsers");
          this.$store.commit("setSnackbar", {
            content: "You have successfully logged in!",
            icon: "mdi-check-circle",
            color: "success ",
            isVisible: true,
          });
        }
      } catch (e) {
        this.$store.commit("setSnackbar", {
          content: e.response.data,
          icon: "mdi-alert-circle",
          color: "error ",
          isVisible: true,
        });
      }
    },
  },
};
</script>

<style>
.login-card {
  border: 1px solid black !important;
  margin-top: 10%;
  height: 400px;
}
.select-user {
  max-width: 150px;
}
</style>
