<template>
  <v-snackbar
    multi-line
    v-model="$store.state.snackbar.isVisible"
    dark
    :timeout="2000"
    :color="$store.state.snackbar.color"
    top
    right
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-layout align-center pr-4>
      <v-icon class="pr-3" dark large>{{ $store.state.snackbar.icon }}</v-icon>
      <v-layout column>
        <div>{{ $store.state.snackbar.content }}</div>
      </v-layout>
    </v-layout>

    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="$store.commit('closeSnackbar')"> Close </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  name: 'Snackbar',
  computed: {
    model: {
      get() {
        return this.$store.getters.getSnackbar;
      },
      set(v) {
        return this.$store.commit('setSnackbar', v);
      },
    },
  },
};
</script>
