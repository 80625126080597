// let adminUrl = "http://localhost:3000";
// let loginUrl = "http://localhost:3001";
import axios from 'axios';

// const imageUploadUrl = 'http://localhost:1330';
// const imageUploadUrl = 'https://test-image.palettier.com';
// const adminUrl = process.env.VUE_APP_API_URL;
const imageUploadUrl = process.env.VUE_APP_IMAGE_UPLOAD_URL;

export default {
  uploadImage: (file, callback) => {
    console.log(file, 'file here');
    const formData = new FormData();
    formData.append('file', file);
    axios
      .post(`${imageUploadUrl}/image/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: localStorage.accessToken,
        },
      })
      .then((result) => {
        callback(result);
      });
  },
};
