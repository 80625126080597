import axios from 'axios';


const adminUrl = process.env.VUE_APP_API_URL;
const adminUrlIframe = process.env.VUE_APP_WL_API_URL;
const userUrl = process.env.VUE_APP_AUTH_URL;
const IframeUserUrl = process.env.VUE_APP_WL_AUTH_URL;
const apiIframeUrl = process.env.VUE_APP_WL_API_URL;

// const uploadUrl = process.env.VUE_APP_FILE_UPLOAD_URL;

export default {
  async getAllProducts(data) {
    // const data = {};
    console.log('working');
    return axios.post(`${adminUrlIframe}/iframe/product/getProductListForAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createProduct(data) {
    return axios.post(`${adminUrlIframe}/iframe/excelImport/entryProductFromAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateProduct(data) {
    return axios.post(`${adminUrlIframe}/iframe/excelImport/updateProductFromAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteProduct(params) {
    console.log('working');
    // const data = {};
    return axios.delete(`${adminUrlIframe}/iframe/product/deleteDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getOneProduct(params) {
    console.log('working');
    return axios.get(`${adminUrlIframe}/iframe/product/getOneAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateSingleProduct(params, data, callback) {
    axios
      .post(`${adminUrlIframe}/iframe/product/updateDataAdmin/${params}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((e) => {
        callback(e);
      });
  },
  async updateProductStatus(data, callback) {
    axios
      .post(`${adminUrlIframe}/iframe/product/changeProductStatusAdmin`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((e) => {
        callback(e);
      });
  },
  async createProductQuery(data, callback) {
    axios
      .post(`${adminUrlIframe}/iframe/productQuery/create`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((e) => {
        callback(e);
      });
  },
  async getColorCustomizationsData() {
    return axios.get(`${adminUrlIframe}/iframe/common/getColorCustomizationsData`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getStyleCustomizations() {
    console.log(localStorage.accessToken);
    return axios.get(`${adminUrlIframe}/iframe/common/getFormTwoData`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateManyProduct(data, callback) {
    axios
      .post(`${adminUrlIframe}/iframe/product/updateManyDataAdmin/`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((e) => {
        callback(e);
      });
  },
  async getAllCategoryItems() {
    return axios.get(`${adminUrlIframe}/iframe/common/getCategoryItems`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  updateProductSet(data) {
    try {
      return axios.post(`${adminUrlIframe}/iframe/product/updateProductSetAdmin`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      });
    } catch (error) {
      return error;
    }
  },
  async getAllBrands() {
    return axios.get(`${IframeUserUrl}/user/iframe/getAllBrandsAdmin`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async syncElasticSearchData() {
    try {
      return axios.post(
        `${adminUrlIframe}/iframe/elasticSearch/syncElasticSearchData`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.accessToken,
          },
        }
      );
    } catch (error) {
      return error;
    }
  },

  async syncParticularProductToElasticSearchData(data) {
    try {
      return axios.post(
        `${adminUrlIframe}/iframe/elasticSearch/syncParticularProductToElasticSearchData`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.accessToken,
          },
        }
      );
    } catch (error) {
      return error;
    }
  },
  deleteManyProductAdmin(data) {
    try {
      return axios.post(`${adminUrlIframe}/iframe/product/deleteManyProductAdmin`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      });
    } catch (error) {
      return error;
    }
  },

  async uploadImagesFromBox(data) {
    try {
      var result = await axios.post(`${adminUrlIframe}/box/getAllFolderImages`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      });
      return result;
    } catch (error) {
      return error;
    }
  },

  async skuUpdateForAll(data) {
    try {
      return axios.post(
        `${adminUrlIframe}/iframe/product/changeTheSkuNameForAllProductsOfVendor`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.accessToken,
          },
        }
      );
    } catch (error) {
      return error;
    }
  },
  async deleteProductElasticSync(data) {
    console.log(localStorage.accessToken, 'local--');
    return axios.post(`${adminUrlIframe}/iframe/elasticSearch/deleteData`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },

  async uploadImagesGoogleDrive(data) {
    try {
      var result = await axios.post(
        `${adminUrlIframe}/iframe/googledrive/googleDriveToAWSlink`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.accessToken,
          },
        }
      );
      return result;
    } catch (error) {
      return error;
    }
  },
  async exportProductData(data){
    return axios.post(`${apiIframeUrl}/iframe/report/downloadProductWiseData`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
};
