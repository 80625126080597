import axios from 'axios';

const loginUrl = process.env.VUE_APP_AUTH_URL;
const loginiframeUrl = process.env.VUE_APP_WL_AUTH_URL;

export default {
  login: (data) =>
    axios.post(`${loginiframeUrl}/user/iframe/login`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getUser: (data) =>
    axios.post(`${loginiframeUrl}/user/iframe/getUser`, data, {
      headers: {
        Authorization: localStorage.accessToken,
        'Content-Type': 'application/json',
      },
    }),
};
