<template>
  <div class="mb-8">
    <v-app-bar color="white" class="flex-grow-0" app dark>
      <div class="my-auto py-4 px-4 mr-4" @click.stop="mini = !mini">
        <img src="@/assets/Images/navIcon.svg" alt="hamburger" />
      </div>
      <div class="">
        <img src="@/assets/Images/logo.svg" alt="Palettier-logo" />
      </div>
      <v-spacer></v-spacer>
      <router-link to="/login" class="router-link">
        <v-btn color="#dc3545" dark v-on:click="logout"> Logout </v-btn>
      </router-link>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" :mini-variant.sync="mini" permanent>
      <v-card class="d-flex admin-card justify-space-around">
        <div class="font-16 poppins-bold py-5">Admin</div>
      </v-card>

      <v-list-group
        no-action
        v-for="navLink in navLinks"
        :key="navLink.text"
        class="card-section"
        @click="route(navLink.link)"
      >
        <v-list-item slot="activator" :to="navLink.route">
          <v-list-item-content>
            <div id="button" class="text-decoration-none d-flex py-2">
              <div class="pt-1">
                <v-img :src="navLink.image" alt="edit" width="20px" />
              </div>
              <div class="font-18 poppins-medium black-txt ml-5 pt-1">
                {{ navLink.title }}
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="sub in navLink.subLinks" :key="sub.text">
          <v-card class="subcard-section d-flex py-2" width="100%">
            <v-list-item-content>
              <router-link :to="sub.route" id="button" class="text-decoration-none d-flex">
                <div class="font-18 poppins-medium black-txt ml-14">
                  {{ sub.text }}
                </div>
              </router-link>
            </v-list-item-content>
          </v-card>
        </v-list-item>
      </v-list-group>
    </v-navigation-drawer>
  </div>
</template>

<script>
import users from '@/assets/Images/users.svg';
import orders from '@/assets/Images/orders.svg';
import custom from '@/assets/Images/custom.svg';
import Vendor from '@/assets/Images/vimag.svg';
import home from '@/assets/Images/home.svg';
import product from '@/assets/Images/product.svg';
import stylistAssist from '@/assets/Images/stylistAssist.svg';
import iFrameIcon from '@/assets/Images/iframe.svg';

export default {
  name: 'App',
  data: () => ({
    showCreditCard: true,
    changeBackgroundColor: false,
    mini: true,
    drawer: false,
    navLinks: [
      // {
      //   title: 'Home',
      //   image: home,
      //   icon: 'mdi-dialpad',
      //   // link: '/home',
      // },
      {
        title: 'Designers',
        image: iFrameIcon,
        icon: 'mdi-cog',
        link: '/iframe',
        subLinks: [
          { text: 'Select Designer', route: '/iframe/vendors' },
          {
            text: `Edit Designer`,
            route: `/iframe/editVendor/`,
          },
        ],
      },
      {
        title: 'Landing Page',
        icon: 'mdi-cog',
        link: '/viewBanners',
        image: home,
      },

      {
        icon: 'mdi-dialpad',
        title: 'Home',
        link: '',
        image: home,
        subLinks: [
          { text: 'Discover More', route: '/createDiscoverMore' },
          { text: 'Collections', route: '/viewCollections' },
          { text: 'Latest Arrivals', route: '/viewlatestArrivals' },
          { text: 'Custom Background', route: '/viewCustom' },
        ],
      },
      {
        title: 'Users',
        image: users,
        icon: 'mdi-view-dashboard',
        link: '/viewUsers',
      },
      {
        title: 'Products',
        image: product,
        icon: 'mdi-cog',
        link: '/viewProducts',
      },
      // {
      //   title: 'Orders',
      //   image: orders,
      //   icon: 'mdi-cog',
      //   link: '/orders',
      // },
      // {
      //   title: 'Vendors',
      //   image: Vendor,
      //   icon: 'mdi-cog',
      //   link: '/viewVendors',
      // },
      {
        title: 'Stylist Assist',
        image: stylistAssist,
        icon: 'mdi-cog',
        link: '/viewAppointments',
      },
      {
        title: 'Customization',
        image: custom,
        icon: 'mdi-cog',
        link: '',
        subLinks: [
          { text: 'Products', route: '/customizationProducts' },
          { text: 'Template', route: '/customTempletes' },
          { text: 'Additions', route: '/customAddition' },
          { text: 'Replacements', route: '/customReplacement' },
          { text: 'Custom Background', route: '/viewCustom' },
        ],
      },
      {
        title: 'Create Designers',
        image: Vendor,
        icon: 'mdi-cog',
        link: '/viewVendors',
      },
    ],
  }),
  methods: {
    toggle() {
      this.showCreditCard = !this.showCreditCard;
    },
    route(route) {
      this.$router.push(route);
    },
    logout() {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userType');
      localStorage.removeItem('activeTab');
      localStorage.removeItem('product');
      localStorage.removeItem('selectedVendor');

      this.$store.commit('setSnackbar', {
        content: 'You have successfully logged Out!',
        icon: 'mdi-check-circle',
        color: 'success ',
        isVisible: true,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.admin-card {
  border-radius: 0px !important;
}
.v-list--nav .v-list-item {
  padding: 0;
}
.card-section {
  border-bottom: 1px solid #d4d7da !important;
  border-radius: 0px !important;
  width: 100%;
}
.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 0;
}
.v-list-item {
  padding: 0px 0px !important;
}
.subcard-section {
  border-top: 1px solid #d4d7da !important;
  border-radius: 0px !important;
  max-width: 100% !important;
}
.router-link {
  text-decoration: none;
  color: inherit;
}
</style>
