<template>
  <div class="">
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">Products</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">
            List of Products
            <span v-if="products" class="tableTotal font-14 poppins-medium"
              >(Total : {{ products.length }})</span
            >
          </div>
          <div class="float-right d-flex mx-4">
            <v-btn
              v-show="selectedProducts.length > 0"
              color="red"
              dark
              class="Create-btn btn btn-success btn-small mr-4"
              @click="deleteManyConfirm()"
            >
              Delete
            </v-btn>

            <div>
              <v-dialog v-model="dialog" width="50%">
                <template v-slot:activator="{ on, attrs }">
                  <b-button variant="secondary" v-bind="attrs" v-on="on">
                    Filter
                    <b-icon-filter></b-icon-filter>
                  </b-button>
                </template>

                <v-card>
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <form name="details" class="formPart text-left">
                        <div class="pt-3">
                          <div class="form-group col">
                            <label for="name">Name:</label>
                            <input
                              type="name"
                              class="form-control"
                              id="name"
                              placeholder="Enter Name"
                              v-model="filter.name"
                            />
                          </div>
                        </div>
                        <div class="pt-3">
                          <div class="form-group col">
                            <label for="name"> Palettier SKU:</label>

                            <input
                              type="name"
                              class="form-control"
                              id="name"
                              placeholder="Enter SKU"
                              v-model="filter.sku"
                            />
                          </div>
                        </div>

                        <div class="pt-3">
                          <div class="form-group col">
                            <label for="name"> Designer SKU:</label>
                            <input
                              type="name"
                              class="form-control"
                              id="name"
                              placeholder="Enter SKU"
                              v-model="filter.designerStyleCode"
                            />
                          </div>
                        </div>
                        <!-- Brand Name -->
                        <div class="pt-3">
                          <div class="form-group col">
                            <label for="name">Brand Name:</label>

                            <v-select
                              item-text="brandName"
                              :items="brands"
                              v-model="filter.brandName"
                              class="pt-0"
                              append-icon="▼"
                              dense
                              solo
                            ></v-select>
                          </div>
                        </div>
                        <!-- Price-->
                        <div class="mx-4 mt-2">
                          <v-range-slider
                            v-model="range"
                            :max="max"
                            :min="min"
                            hide-details
                            class="align-center range-txt"
                          >
                            <template v-slot:prepend>
                              <label class="pt-2">INR</label>
                              <v-text-field
                                :value="range[0]"
                                class="mt-0 pt-0 ml-2"
                                hide-details
                                type="number"
                                style="width: 60px"
                              ></v-text-field>
                            </template>
                            <template v-slot:append>
                              <label class="pt-2">INR</label>
                              <v-text-field
                                :value="range[1]"
                                class="mt-0 pt-0 ml-2"
                                hide-details
                                type="number"
                                style="width: 60px"
                              ></v-text-field>
                            </template>
                          </v-range-slider>
                        </div>
                        <!-- type selecte and Category -->
                        <v-row no-gutters class="mt-4 mx-6">
                          <v-col cols="6">
                            <v-select
                              v-model="filter.type"
                              :items="types"
                              label="Type"
                              dense
                              solo
                              append-icon="▼"
                              class="mr-4"
                            ></v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                              v-model="filter.productCategory"
                              :items="categories"
                              label="Category"
                              dense
                              solo
                              append-icon="▼"
                              width="260"
                              class="mr-4"
                            ></v-select>
                          </v-col>
                        </v-row>

                        <!-- type Status and Gender -->
                        <v-row no-gutters class="mt-4 mx-6">
                          <v-col cols="6">
                            <v-select
                              v-model="filter.category"
                              :items="gender"
                              label="Gender"
                              dense
                              solo
                              append-icon="▼"
                              class="mr-4"
                            ></v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                              v-model="filter.productStatus"
                              :items="status"
                              label="Status"
                              dense
                              solo
                              append-icon="▼"
                              class="mr-4"
                            ></v-select>
                          </v-col>
                        </v-row>

                        <!-- button -->
                        <div class="btnPart text-center py-4">
                          <button
                            @click="
                              active = true;
                              filterProducts();
                            "
                            type="submit"
                            class="btn btn-success px-6"
                          >
                            Save
                          </button>

                          <button type="reset" class="btn btn-danger mx-2" v-on:click="resetForm()">
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </v-card>
              </v-dialog>
            </div>
            <div class="float-right ml-4">
              <router-link to="createProducts" class="Create-btn btn btn-success btn-small"
                >Create</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center" align="center">
        <div class="col-md-12">
          <div class="widget">
            <div class="table-area">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-1 text-left"></th>
                      <th class="col-md-1 text-left">#</th>
                      <th class="col-md-3 text-left">Palettier SKU Code</th>
                      <th class="col-md-3 text-left">Designer SKU</th>
                      <th class="col-md-2 text-left">Product Name</th>
                      <th class="col-md-2 text-left">Brand Name</th>
                      <th class="col-md-2 text-left">Status</th>
                      <th class="col-md-2 text-left">Customisable</th>
                      <!-- <th class="col-md-2">Size & Price</th> -->

                      <th class="col-md-1">Type</th>
                      <!-- <th class="col-md-1">Category</th> -->

                      <th class="colo-md-1">Gender</th>
                      <th class="colo-md-1">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in products" v-bind:key="item._id">
                      <td>
                        <input
                          type="checkbox"
                          v-model="selectedProducts"
                          :value="item._id"
                          @click="hidden = !hidden"
                        />
                      </td>
                      <td class="text-left">
                        {{ index + 1 + (page - 1) * 20 }}
                      </td>
                      <td class="text-left">{{ item.sku }}</td>
                      <td class="text-left">
                        <div v-if="item.designerStyleCode">{{ item.designerStyleCode }}</div>
                        <div v-else class="text-left">-</div>
                      </td>
                      <td class="text-left">
                        <div>{{ item.name }}</div>
                      </td>
                      <!-- <td>
                        <div
                          v-if="
                            item &&
                            item.user &&
                            item.user.length &&
                            item.user[0] &&
                            item.user[0].firstName
                          "
                        >
                          {{ item.user[0].firstName }}
                        </div>
                        <div v-else>-</div>
                      </td> -->
                      <td class="text-left">
                        <div
                          v-if="
                            item &&
                            item.user &&
                            item.user.length &&
                            item.user[0] &&
                            item.user[0].brandName
                          "
                        >
                          {{ item.user[0].brandName }}
                        </div>
                        <div
                          v-else-if="
                            item &&
                            item.user &&
                            item.user.length &&
                            item.user[0] &&
                            item.user[0].name
                          "
                        >
                          {{ item.user[0].name }}
                        </div>
                        <div v-else>-</div>
                      </td>

                      <td>{{ item.productStatus }}</td>
                      <td class="text-left">
                        <div v-if="item.isCustomized === true">Yes</div>
                        <div v-else>No</div>
                      </td>
                      <!-- <td>
                        <v-select
                          v-model="selectedSize[index]"
                          :items="item.deliveryDetails"
                          label="Size & Price"
                          class="select"
                          append-icon="▼"
                        >
                          <template v-slot:selection="{ item }">
                            <v-row no-gutters>
                              <v-col cols="5">
                                <div class="float-right">{{ item.size }}</div>
                              </v-col>
                              <v-col cols="2">
                                <div class="float-right">-</div>
                              </v-col>
                              <v-col cols="5">
                                <div class="float-right">{{ item.mrp }}/-</div>
                              </v-col>
                            </v-row>
                          </template>
                          <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-content>
                                <v-row no-gutters>
                                  <v-col cols="5">
                                    <div>{{ item.size }}</div>
                                  </v-col>
                                  <v-col cols="2">
                                    <div>-</div>
                                  </v-col>
                                  <v-col cols="5">
                                    <div>{{ item.mrp }}/-</div>
                                  </v-col>
                                </v-row>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                      </td> -->

                      <td class="text-left">
                        <div v-if="item.type == 'single'">Single</div>
                        <div v-else>Set</div>
                      </td>
                      <!-- <td class="text-left">
                        <div>{{ item.productCategory }}</div>
                      </td> -->

                      <td class="text-left">
                        <div>{{ item.category }}</div>
                      </td>
                      <!-- v-repeat -->
                      <td class="text-left">
                        <div class="d-flex">
                          <!-- <div class="mr-6">
                            <router-link
                              class="btn btn-success"
                              :to="{
                                name: 'editProductsTable',
                                params: { id: item._id },
                              }"
                              append
                            >
                              <b-icon-pencil class="text-white"></b-icon-pencil>
                            </router-link>
                          </div> -->
                          <div class="mr-6">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="green"
                                  :to="{
                                    name: 'editProductsTable',
                                    params: { id: item._id },
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                  append
                                >
                                  <b-icon-pencil class="text-white"></b-icon-pencil>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                          </div>
                          <div class="mr-6">
                            <div class="mt-2 mb-2" v-if="loader === index" align="center">
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </div>
                            <v-tooltip bottom v-else>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="white"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="exportProductDataById(item._id, index)"
                                >
                                  <b-icon-download color="black"></b-icon-download>
                                </v-btn>
                              </template>
                              <span>Export</span>
                            </v-tooltip>
                          </div>

                          <!-- <div
                            class="deletedModal mr-6"
                            @click="$router.push('/editProduct')"
                          >
                            <b-button variant="secondary">
                              <b-icon-file-spreadsheet></b-icon-file-spreadsheet>
                            </b-button>
                          </div> -->
                          <!-- <div class="mr-6">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="secondary"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="$router.push('/editProduct')"
                                >
                                  <b-icon-file-spreadsheet color="white"></b-icon-file-spreadsheet>
                                </v-btn>
                              </template>
                              <span>Line Sheet</span>
                            </v-tooltip>
                          </div> -->

                          <!-- <div class="deletedModal">
                            <b-button
                              variant="danger"
                              v-b-modal.delete-modal
                              @click="deleteProductById(item._id)"
                            >
                              <b-icon-trash></b-icon-trash>
                            </b-button>
                          </div> -->

                          <div class="mr-6">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="deleteConfirm(item._id)"
                                >
                                  <b-icon-trash color="white"></b-icon-trash>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <div></div>
                    <!-- end v-repeat -->
                  </tbody>
                </table>
              </div>
              <div
                class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                v-if="products.length === 0"
              >
                No Data Found
              </div>

              <!-- Use text in props -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-4" v-if="products.length >= 1">
      <v-pagination
        class="mb-2 float-center"
        v-model="page"
        :length="totalPages"
        :per-page="20"
        prev-icon="<"
        next-icon=">"
        @input="filterProducts()"
      />
    </div>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import productService from '../../services/productService';
import ConfirmDlg from '../../components/ConfirmDlg.vue';
import _ from 'lodash';

export default {
  components: { ConfirmDlg },
  data() {
    return {
      loader:'',
      dialog: false,
      searchText: '',
      types: ['Single', 'Set'],
      status: ['draft', 'reviewed', 'active', 'deactivated'],
      categories: [],

      gender: ['Women', 'Men'],
      min: 500,
      max: 5000,
      range: [500, 5000],
      priceFlag: false,
      filter: {
        productCategory: '',
        type: '',
        category: '',
        name: '',
        brandName: '',
        fromPrice: 0,
        toPrice: 0,
        productStatus: '',
        sku: '',
        hidden: true,
        designerStyleCode: '',
      },
      products: [
        {
          name: 'User 1',
          brandName: 'admin',
          price: '40000',
          type: 'Kurta',
          category: 'Single',

          gender: 'Male',
        },
        {
          name: 'User 1',
          brandName: 'admin',
          price: '40000',
          type: 'Kurta',
          category: 'Single',

          gender: 'Male',
        },
      ],
      page: 1,
      totalPages: 1,
      productPrice: 0,
      selectedSize: ['Free Size'],
      brands: [],
      active: false,
      selectedProducts: [],
    };
  },
  created() {
    this.getAllProducts();
    this.getAllBrands();
    this.getAllCategoryItems();
  },

  methods: {
    async exportProductDataById(id, index) {
      console.log("Prduct Id", id, localStorage.getItem('selectedVendor'))
      this.loader = index;

      const data = {};
      const vendorID = JSON.parse(localStorage.getItem('selectedVendor'));
      data.vendorId = vendorID;
      data.productId = id;
      const result = await productService.exportProductData(data);
      if (result.status === 200) {
        window.open(result.data);
        this.$store.commit('setSnackbar', {
          content: 'Product Data Exported Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
      this.loader = ''
    },
    async getAllProducts() {
      this.dialog = false;
      if (this.$route.query.Filters) {
        this.filter = await JSON.parse(this.$route.query.Filters);
      }
      if (this.$route.query.page) {
        this.page = await JSON.parse(this.$route.query.page);
      }
      var temp = {};
      temp.filter = _.clone(this.filter);

      if (this.active == true) {
        this.page = 1;
        temp.page = this.page;
      } else {
        temp.page = this.page;
      }

      if (temp.filter.type != '') {
        if (temp.filter.type == 'Set') {
          temp.filter.type = 'compound';
        } else if (temp.filter.type == 'Single') {
          temp.filter.type = 'single';
        }
      }

      if (temp.filter.category != '') {
        if (temp.filter.category == 'Men') {
          temp.filter.category = 'Male';
        } else if (temp.filter.category == 'Women') {
          temp.filter.category = 'Female';
        }
      }
      let vendor = localStorage.getItem('selectedVendor');
      if (vendor && vendor != 'undefined') {
        temp.user = JSON.parse(localStorage.getItem('selectedVendor'));
      }
      const result = await productService.getAllProducts(temp);

      this.products = [];
      this.products = await result.data.data;
      this.totalPages = await result.data.paginationData.totalPages;

      this.dialog = false;
      this.active = false;
    },
    async filterProducts() {
      this.dialog = false;
      this.$router.push({
        path: '/viewProducts',
        query: {
          Filters: JSON.stringify(this.filter),
          page: JSON.stringify(this.page),
        },
      });
      this.getAllProducts();
    },
    async resetForm() {
      let filter = {
        productCategory: '',
        type: '',
        category: '',
        name: '',
        brandName: '',
        fromPrice: 0,
        toPrice: 0,
        productStatus: '',
        sku: '',
        designerStyleCode: '',
      };
      this.filter = filter;
      this.$router.push({
        path: '/viewProducts',
        query: {
          Filters: JSON.stringify(this.filter),
        },
      });
      await this.getAllProducts();
    },
    async deleteProductById(id) {
      const result = await productService.deleteProduct(id);

      if (result.status === 200) {
        await this.$store.commit('setSnackbar', {
          content: 'Deleted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        await this.deleteProductElasticSync(id);
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
    async getAllCategoryItems() {
      this.categories = [];
      const result = await productService.getAllCategoryItems();

      let women = await result.data.female.map((item) => {
        return item.name;
      });

      let Men = await result.data.male.map((item) => {
        return item.name;
      });

      this.categories = [...women, ...Men];
      console.log(this.categories.length, 'length', this.categories);
    },

    async deleteConfirm(id) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.deleteProductById(id);
      }
    },
    async getAllBrands() {
      const result = await productService.getAllBrands();

      this.brands = await result.data;
    },
    async multiProductsDelete() {
      const data = {};
      data.productIds = this.selectedProducts;
      const result = await productService.deleteManyProductAdmin(data);

      if (result.status === 200) {
        await this.$store.commit('setSnackbar', {
          content: 'Deleted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        this.$router.go();
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
    async deleteManyConfirm() {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.multiProductsDelete();
      }
    },
    async deleteProductElasticSync(id) {
      const data = [];
      data.push(id);

      const result = await productService.deleteProductElasticSync(data);

      this.$router.go();
    },
  },
};
</script>

<style lang="scss" scoped>
// .range-txt {
//   .v-text-field > .v-input__control > .v-input__slot:after,
//   .v-text-field > .v-input__control > .v-input__slot:before {
//     display: none !important;
//   }
// }
.select {
  width: 250px;
  max-height: 60px;
  font-size: 16px;
}

.Create-btn {
  color: white !important;
}
</style>
