<template>
  <div>
    <!-- Back button  if u want to use this breadcrumbs just remove the hidden class -->
    <div class="heading-sec">
      <div class="row">
        <div class="col-md-4 column">
          <div class="heading-profile">
            <div class="font-24 mt-10 ml-4">Create User</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Top Bar Chart -->
    <v-card class="mt-2 mx-4">
      <div class="panel-content">
        <div class="row">
          <div class="col-md-12">
            <div class="widget">
              <div class="form-elements-sec">
                <div class="form-body">
                  <form
                    name="details"
                    class="formPart text-left"
                    @submit.prevent="submitForm(details)"
                  >
                    <!-- Name -->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.name.$error,
                        }"
                      >
                        <label for="name">Name:</label>
                        <input
                          type="name"
                          class="form-control"
                          id="name"
                          placeholder="Enter Name"
                          v-model.trim="details.name"
                          @input="setTouch('name')"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.name.required && $v.details.name.$error"
                        >
                          Name is required
                        </div>
                      </div>
                    </div>
                    <!-- Email -->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.email.$error,
                        }"
                      >
                        <label for="email">Email:</label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="name@gmail.com"
                          v-model.trim="details.email"
                          @input="setTouch('email')"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.email.required && $v.details.email.$error"
                        >
                          Email is required
                        </div>
                      </div>
                    </div>
                    <!-- phone no -->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.mobile.$error,
                        }"
                      >
                        <label for="mobile">Mobile No:</label>
                        <input
                          type="number"
                          class="form-control"
                          id="mobile"
                          placeholder="Enter Mobile No"
                          v-model.trim="details.mobile"
                          @input="setTouch('mobile')"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.mobile.required && $v.details.mobile.$error"
                        >
                          Mobile No is required
                        </div>
                      </div>
                    </div>
                    <!-- password-->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.password.$error,
                        }"
                      >
                        <label for="password">Password:</label>
                        <input
                          type="password"
                          class="form-control"
                          id="password"
                          placeholder="Enter Password No"
                          v-model.trim="details.password"
                          @input="setTouch('password')"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.password.required && $v.details.password.$error"
                        >
                          Password is required
                        </div>
                      </div>
                    </div>
                    <!-- button -->
                    <div class="btnPart text-center my-4">
                      <button type="submit" class="btn btn-success">Save</button>
                      <p class="typo__p" v-if="submitStatus === 'OK'">
                        Thanks for your submission!
                      </p>
                      <p class="typo__p" v-if="submitStatus === 'ERROR'">
                        Please fill the form correctly.
                      </p>

                      <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import customerService from '../../services/customerService';
// import headerCustom from '@/components/Header.vue';
// import apiservice from "@/service/apiservice";

export default {
  data() {
    return {
      details: {
        name: '',
        email: '',
        mobile: '',
        password: '',
        userType: 'Customer',
      },
      submitStatus: null,
    };
  },

  validations: {
    details: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      mobile: {
        required,
      },
      password: {
        required,
      },
    },
  },

  methods: {
    // this method use for validation error
    setTouch(fields) {
      if (fields === 'name') {
        this.$v.details.name.$touch();
      } else if (fields === 'email') {
        this.$v.details.email.$touch();
      } else if (fields === 'mobile') {
        this.$v.details.mobile.$touch();
      } else if (fields === 'password') {
        this.$v.details.password.$touch();
      }
    },
    // this is use for save button
    async submitForm(details) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      } else {
        //     // do your submit logic here
        this.submitStatus = 'OK';
        //     apiservice.saveUser(this.details, (resultData) => {
        //       if (resultData.status === 200) {
        //         this.$toaster.success('Data saved');
        //         this.$router.push('/viewUser');
        //       } else {
        //
        //       }
        //     });
        //   }
        const data = {};
        data.vendor = JSON.parse(localStorage.getItem('selectedVendor'));
        data.name = details.name;
        data.email = details.email;
        data.mobile = details.email;
        data.password = details.password;
        data.userType = 'Customer';
        const result = await customerService.createUser(data);
        if (result.status === 200) {
          if (result.data.data == 'User Already Exists') {
            await this.$store.commit('setSnackbar', {
              content: 'User Already Exists',
              icon: 'mdi-alert-circle',
              color: 'error ',
              isVisible: true,
            });
          } else {
            await this.$store.commit('setSnackbar', {
              content: 'Submitted Successfully',
              icon: 'mdi-check-circle',
              color: 'success ',
              isVisible: true,
            });
            this.$router.push('/viewUsers');
          }
        } else {
          await this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      }
    },
    // this method use for cancel button
    resetForm() {
      this.details = {
        name: '',
        email: '',
        mobile: '',
        password: '',
        userType: 'Customer',
      };
      this.$nextTick(() => {
        this.$v.$reset();
        this.$router.push('/viewUsers');
      });
    },
    // async createUser(details) {
    //   console.log('result');
    //   //  this.users = [];
    //   //   this.formData.page = pageNo;
    //   //   this.formData.limit = this.perPage;
    //   //   this.formData.search = this.searchText;
    //   const result = await customerService.getAllCustomers(details);
    //   // this.users = result.data.data;
    //   //
    //   console.log('result', result);
    // },
  },
};
</script>
<style lang="scss" scoped>
.validation {
  color: red;
}
</style>
