<template>
  <div>
    <!-- Back button  if u want to use this breadcrumbs just remove the hidden class -->
    <div class="heading-sec">
      <div class="row">
        <div class="col-md-4 column">
          <div class="heading-profile">
            <div class="font-24 mt-10 ml-4">Create New Designer</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Top Bar Chart -->
    <v-card class="mt-2 mx-4">
      <div class="panel-content">
        <div class="row">
          <div class="col-md-12">
            <div class="widget">
              <div class="form-elements-sec">
                <div class="form-body">
                  <form
                    name="details"
                    class="formPart text-left"
                    @submit.prevent="submitForm(details)"
                  >
                    <!---Designer Selection-->
                    <!-- <div>
                      <div class="form-group mx-4 pt-4" v-if="brands">
                        <label for="email"> Designer:</label>

                        <v-select
                          prepend-icon=""
                          item-text="name"
                          v-model="selectedBrand"
                          :items="brands"
                          class="form-control"
                          append-icon="▼"
                          @change="updateVendor()"
                        >
                          <template v-slot:selection="{ item }">
                            <v-row no-gutters>
                              <v-col cols="12">
                                <div>{{ item.brandName }}</div>
                              </v-col>
                            </v-row>
                          </template>
                          <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-content>
                                <div>{{ item.brandName }}</div>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                         <div
                          class="validation"
                          v-if="!$v.details.email.email.required && $v.details.email.email.$error"
                        >
                          Email is required
                        </div>
                      </div>
                    </div> -->

                    <!-- Name -->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.brandName.$error,
                        }"
                      >
                        <label for="name">Brand Name:</label>
                        <input
                          type="name"
                          class="form-control"
                          id="name"
                          placeholder="Enter Brand Name"
                          v-model.trim="details.brandName"
                          @input="setTouch('brandName')"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.brandName.required && $v.details.brandName.$error"
                        >
                          Brand Name is required
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.brandName.$error,
                        }"
                      >
                        <label for="name">Brand Url Code(Only Alphabets):</label>
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          placeholder="Enter Brand Url Code"
                          v-model.trim="details.vendorUrlCode"
                          @input="validateInput"
                          pattern="[A-Za-z]+"
                          title="Please enter only alphabets with no spaces in between."
                        />
                        <div
                          class="validation"
                          v-if="
                            !$v.details.vendorUrlCode.required && $v.details.vendorUrlCode.$error
                          "
                        >
                          Brand Url Code is required
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.brandName.$error,
                        }"
                      >
                        <label for="identificationCode">Code (Min 3 characters required) : </label>
                        <input
                          type="text"
                          class="form-control"
                          id="code"
                          placeholder="Enter Brand Code"
                          v-model.trim="details.identificationCode"
                          @input="setTouch('identificationCode')"
                          minlength="3"
                          maxlength="3"
                          oninput="this.value=this.value.toUpperCase()"
                        />
                        <!-- <div
                            class="validation"
                            v-if="
                              !$v.details.identificationCode.required &&
                              !$v.details.marginPercentage.minlength &&
                              !$v.details.marginPercentage.maxlength &&
                              $v.details.identificationCode.$error
                            "
                          > -->
                        <div
                          class="validation"
                          v-if="
                            !$v.details.identificationCode.required &&
                            $v.details.identificationCode.$error
                          "
                        >
                          Code is required
                        </div>
                      </div>
                    </div>

                    <!-- Email -->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.email.$error,
                        }"
                      >
                        <label for="email">Email:</label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="name@gmail.com"
                          v-model.trim="details.email"
                          @input="setTouch('email')"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.email.required && $v.details.email.$error"
                        >
                          Email is required
                        </div>
                      </div>
                    </div>
                    <!-- phone no -->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.mobile.$error,
                        }"
                      >
                        <label for="mobile">Mobile No:</label>
                        <input
                          type="number"
                          class="form-control"
                          id="mobile"
                          placeholder="Enter Mobile No"
                          v-model.trim="details.mobile"
                          @input="setTouch('mobile')"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.mobile.required && $v.details.mobile.$error"
                        >
                          Mobile No is required
                        </div>
                      </div>
                    </div>

                    <!-- Plan -->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.plan.$error,
                        }"
                      >
                        <label for="email">Plan:</label>
                        <v-select
                          :items="planTypes"
                          v-model="details.plan"
                          placeholder="Select a Plan Type"
                          append-icon="▼"
                          class="pt-0"
                          @input="setTouch('plan')"
                          dense
                          solo
                        ></v-select>
                        <!-- <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="name@gmail.com"
                          v-model.trim="details.email"
                          @input="setTouch('email')"
                        /> -->
                        <div
                          class="validation"
                          v-if="!$v.details.plan.required && $v.details.email.$error"
                        >
                          Plan type is required
                        </div>
                      </div>
                    </div>

                    <!--Image Upload-->
                    <div
                      class="form-group col"
                      :class="{
                        'form-group--error': $v.details.logo.$error,
                      }"
                    >
                      <div>
                        <label for="logo">Brand Logo Image:</label>
                        <div class="font-12 black--text font-weight-bold">
                          (376 W &#10005; 240 H)
                        </div>
                        <v-file-input
                          prepend-icon=""
                          type="file"
                          class="form-control"
                          id="logo-image"
                          placeholder="Select Image"
                          accept="image/jpeg, image/png"
                          @change="handleLogoImage"
                        ></v-file-input>
                        <div
                          class="validation"
                          v-if="!$v.details.logo.required && $v.details.logo.$error"
                        >
                          Brand Logo Image is required
                        </div>
                      </div>
                      <div class="preview-image border p-2 mt-3 ml-3" v-if="logoUrl">
                        <div v-if="loading" class="loader">Loading...</div>
                        <v-img v-else :src="logoUrl" height="230" width="300px" contain></v-img>
                      </div>
                    </div>

                    <div class="btnPart text-center my-4">
                      <button type="submit" class="btn btn-success" :disabled="loading">Save</button>
                      <!-- <p class="typo__p" v-if="submitStatus === 'OK'">
                          Thanks for your submission!
                        </p>
                        <p class="typo__p" v-if="submitStatus === 'ERROR'">
                          Please fill the form correctly.
                        </p> -->

                      <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {
  required,
  email,
  minValue,
  maxValue,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';
import vendorService from '../../services/vendorService';
import imageUploadService from '../../services/imageUploadService';
import productService from '../../services/productService.js';

// import headerCustom from '@/components/Header.vue';
// import apiservice from "@/service/apiservice";

export default {
  data() {
    return {
      details: {
        brandName: '',
        email: '',
        mobile: '',
        userType: 'Vendor',
        password: '',
        //   marginPercentage: '',
        identificationCode: '',
        logo: '',
        plan: '',
        parentVendor: '',
        vendorUrlCode: '',
      },
      selectedBrand: {},
      submitStatus: null,
      planTypes: ['trial', 'basic', 'pro', 'advance'],
      logoUrl: '',
      brands: [],
      loading: false,
    };
  },

  validations: {
    details: {
      brandName: {
        required,
      },
      email: {
        required,
        email,
      },
      mobile: {
        required,
      },
      // marginPercentage: {
      //   required,
      //   minValue: minValue(0),
      //   maxValue: maxValue(99),
      // },
      identificationCode: {
        required,
        minLengthValue: minLength(3),
        maxLengthValue: maxLength(3),
      },
      plan: {
        required,
      },
      logo: {
        required,
      },
      vendorUrlCode: {
        required,
      },
    },
  },

  methods: {
    // this method use for validation error
    setTouch(fields) {
      if (fields === 'brandName') {
        this.$v.details.brandName.$touch();
      } else if (fields === 'email') {
        this.$v.details.email.$touch();
      } else if (fields === 'mobile') {
        this.$v.details.mobile.$touch();
      }
      // else if (fields === 'marginPercentage') {
      //   this.$v.details.marginPercentage.$touch();
      // }
      else if (fields === 'identificationCode') {
        this.$v.details.identificationCode.$touch();
      }
      //  else if (fields == 'logo') {
      //   this.$v.details.logo.$touch();
      // }
      else if (fields === 'plan') {
        this.$v.details.plan.$touch();
      }
    },
    updateVendor() {
      this.details.brandName = this.selectedBrand.brandName;
      this.details.email = this.selectedBrand.email;
      this.details.mobile = this.selectedBrand.mobile;
      this.details.parentVendor = this.selectedBrand._id;
      this.details.vendorUrlCode = this.selectedBrand.vendorUrlCode;

      this.details.identificationCode = this.selectedBrand.identificationCode;
    },
    // this is use for save button
    async submitForm(details) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      } else {
        this.submitStatus = 'OK';
        const result = await vendorService.createIframeUser(details);
        console.log(this.result);
        if (result.status === 200) {
          if (result.data.data === 'User Already Exists') {
            this.$store.commit('setSnackbar', {
              content: 'Email Already In Use',
              icon: 'mdi-alert-circle',
              color: 'error ',
              isVisible: true,
            });
          } else if (result.data.data === 'User Already Exists with this brand name') {
            this.$store.commit('setSnackbar', {
              content: 'Brand Name Already Exists',
              icon: 'mdi-alert-circle',
              color: 'error ',
              isVisible: true,
            });
          } else if (result.data.data === 'User Already Exists With Url Code') {
            this.$store.commit('setSnackbar', {
              content: 'URL Code Already Exists',
              icon: 'mdi-check-circle',
              color: 'error ',
              isVisible: true,
            });
          } else {
            this.$store.commit('setSnackbar', {
              content: 'Submitted Successfully',
              icon: 'mdi-check-circle',
              color: 'success ',
              isVisible: true,
            });
            this.$router.push('/viewVendors');
          }
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      }
    },
    validateInput() {
      this.details.vendorUrlCode = this.details.vendorUrlCode.replace(/[^A-Za-z]/g, '');
    },
    // this method use for cancel button
    resetForm() {
      this.$router.push('/viewVendors');
    },
    // async handleDesktopImage(file) {
    //   this.desktopUrl = URL.createObjectURL(this.details.desktopLogoImage);
    //   imageUploadService.uploadImage(file, (result) => {
    //     if (result.status === 200) {
    //       this.$store.commit('setSnackbar', {
    //         content: 'Image Uploaded Successfully',
    //         icon: 'mdi-check-circle',
    //         color: 'success ',
    //         isVisible: true,
    //       });
    //       console.log('image', result.data.meta.location);
    //       this.details.desktopLogoImage = result.data.meta.location;
    //       this.preview = result;
    //     } else {
    //       this.$store.commit('setSnackbar', {
    //         content: 'Something went wrong !',
    //         icon: 'mdi-alert-circle',
    //         color: 'error ',
    //         isVisible: true,
    //       });
    //     }
    //   });
    // },
    async handleLogoImage(file) {
      this.logoUrl = URL.createObjectURL(file);
      this.loading = true;

      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.logo = result.data.meta.location;

          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
        this.loading = false;
        console.log(this.details.logo, 'logo link');
      });
    },
    // async getAllDesigners() {
    //   const result = await productService.getAllBrands();
    //   this.brands = await result.data;
    //   console.log(this.brands, 'brands');
    // },
    // async createUser(details) {
    //   console.log('result');
    //   //  this.users = [];
    //   //   this.formData.page = pageNo;
    //   //   this.formData.limit = this.perPage;
    //   //   this.formData.search = this.searchText;
    //   const result = await customerService.getAllCustomers(details);
    //   // this.users = result.data.data;
    //   //
    //   console.log('result', result);
    // },
  },
  created() {
    //this.getAllDesigners();
  },
};
</script>
<style lang="scss" scoped>
.validation {
  color: red;
}
</style>
