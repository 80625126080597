import Vue from 'vue';
import VueRouter from 'vue-router';
import Colors from '../views/Colors.vue';
import viewProducts from '../views/Products/viewProducts.vue';
import ViewVendors from '@/views/Vendor/viewVendors.vue';
import createUser from '../views/User/createUser.vue';
import createVendor from '../views/Vendor/createVendor';

import createProducts from '../views/Products/createProducts.vue';
//import tabSection from '../views/User/tabSection.vue';
import Login from '../views/Login.vue';
import Orders from '../views/Order/viewOrders.vue';
import customizationProducts from '@/views/Customization/customizationProducts.vue';
import customTempletes from '@/views/Customization/customTemplates.vue';
import customAddition from '@/views/Customization/customAddition.vue';
import customReplacement from '@/views/Customization/customReplacement.vue';
//import editIframeVendor from '../views/Iframe/Vendors/editIframeVendor.vue'
//import iframeVendors from '@/views/Iframe/Vendors/iframeVendorsList.vue';
// import Customization from '@/views/Vendor/Customization.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/customization',
    name: 'Customization',
    component: () => import('../views/Customization.vue'),
  },
  {
    path: '/customization/step2/:id',
    name: 'Step2',
    component: () => import('../views/Step2.vue'),
  },
  {
    path: '/customization/step3/:id',
    name: 'Step3',
    component: () => import('../views/Step3.vue'),
  },
  {
    path: '/customization/step4/:id',
    name: 'Step4',
    component: () => import('../views/Step4.vue'),
  },
  {
    path: '/colors',
    name: 'Colors',
    component: Colors,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/viewUsers',
    name: 'viewUsers',
    component: () => import('../views/User/viewUsers.vue'),
  },
  // {
  //   path: '/editUser/:id',
  //   name: 'editUser',
  //   component: () => import('../views/User/editUser.vue'),
  // },
  {
    path: '/viewProducts',
    name: 'viewProducts',
    component: viewProducts,
  },
  {
    path: '/editUser/:id',
    name: 'editUser',
    component: () => import('../views/User/editUser.vue'),
  },
  {
    path: '/viewVendors',
    name: 'ViewVendors',
    component: ViewVendors,
  },
  {
    path: '/iframe/editVendor/',
    name: 'editVendor',
    component: () => import('../views/Vendor/editVendor.vue'),
  },
  {
    path: '/createUser',
    name: 'createUser',
    component: createUser,
  },

  {
    path: '/createVendor',
    name: 'createVendor',
    component: createVendor,
  },

  {
    path: '/createProducts',
    name: '/createProducts',
    component: createProducts,
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
  },
  {
    path: '/customizationProducts',
    name: 'customizationProducts',
    component: customizationProducts,
  },
  {
    path: '/customTempletes',
    name: 'customTempletes',
    component: customTempletes,
  },
  {
    path: '/editOrder/:id',
    name: 'editOrder',
    component: () => import('../views/Order/editOrder.vue'),
  },
  {
    path: '/viewAppointments',
    name: 'viewAppointments',
    component: () => import('../views/Stylist Assist/viewAppointments.vue'),
  },
  {
    path: '/editAppointment/:id',
    name: 'editAppointment',
    component: () => import('../views/Stylist Assist/editAppointment.vue'),
  },
  {
    path: '/createAppointment',
    name: 'createAppointment',
    component: () => import('../views/Stylist Assist/createAppointment.vue'),
  },
  {
    path: '/viewCollections',
    name: 'viewCollections',
    component: () => import('../views/Designers/viewDesigners.vue'),
  },
  {
    path: '/editCollection/:id',
    name: 'editCollection',
    component: () => import('../views/Designers/editDesigners.vue'),
  },
  {
    path: '/createCollection',
    name: 'createCollection',
    component: () => import('../views/Designers/createDesigner.vue'),
  },
  {
    path: '/viewBanners',
    name: 'viewBanners',
    component: () => import('../views/Banners/viewBanners.vue'),
  },
  {
    path: '/editBanners/:id',
    name: 'editBanners',
    component: () => import('../views/Banners/editBanners.vue'),
  },
  {
    path: '/createBanner',
    name: 'createBanner',
    component: () => import('../views/Banners/createBanner.vue'),
  },
  {
    path: '/viewCuratedEdits',
    name: 'viewCuratedEdits',
    component: () => import('../views/CuratedEdits/viewCuratedEdits.vue'),
  },
  {
    path: '/editCurated/:id',
    name: 'editCurated',
    component: () => import('../views/CuratedEdits/editCurated.vue'),
  },
  {
    path: '/createDiscoverMore',
    name: 'createDiscoverMore',
    component: () => import('../views/CuratedEdits/createCurated.vue'),
  },
  {
    path: '/viewTopPicks',
    name: 'viewTopPicks',
    component: () => import('../views/TopPicks/viewTopPicks.vue'),
  },
  {
    path: '/editTopPick/:id',
    name: 'editTopPick',
    component: () => import('../views/TopPicks/editTopPicks.vue'),
  },
  {
    path: '/createTopPick',
    name: 'createTopPick',
    component: () => import('../views/TopPicks/createTopPicks.vue'),
  },
  {
    path: '/viewSpotLight',
    name: 'viewSpotLight',
    component: () => import('../views/SpotLight/viewSpotLight.vue'),
  },
  {
    path: '/editSpotLight/:id',
    name: 'editSpotLight',
    component: () => import('../views/SpotLight/editSpotLight.vue'),
  },
  {
    path: '/createSpotLight',
    name: 'createSpotLight',
    component: () => import('../views/SpotLight/createSpotLight.vue'),
  },
  {
    path: '/editProduct',
    name: 'editProduct',
    component: () => import('../views/Products/editProduct.vue'),
  },
  {
    path: '/editProductsTable/:id',
    name: 'editProductsTable',
    component: () => import('../views/Products/editProductsTable.vue'),
  },
  {
    path: '/viewCategories',
    name: 'viewCategories',
    component: () => import('../views/Categories/viewCategories.vue'),
  },
  {
    path: '/editCategory/:id',
    name: 'editCategory',
    component: () => import('../views/Categories/editCategory.vue'),
  },
  {
    path: '/createCategory',
    name: 'createCategory',
    component: () => import('../views/Categories/createCategory.vue'),
  },
  {
    path: '/viewPosts',
    name: 'viewPosts',
    component: () => import('../views/PalettierPosts/viewPosts.vue'),
  },
  {
    path: '/editPost/:id',
    name: 'editPost',
    component: () => import('../views/PalettierPosts/editPosts.vue'),
  },
  {
    path: '/createPost',
    name: 'createPost',
    component: () => import('../views/PalettierPosts/createPosts.vue'),
  },
  {
    path: '/viewCustom',
    name: 'viewCustom',
    component: () => import('../views/CustomBackground/viewCustom.vue'),
  },
  {
    path: '/editCustom/:id',
    name: 'editCustom',
    component: () => import('../views/CustomBackground/editCustom.vue'),
  },
  {
    path: '/createCustom',
    name: 'createCustom',
    component: () => import('../views/CustomBackground/createCustom.vue'),
  },
  {
    path: '/customizeAddition',
    name: 'CustomizeAddition',
    component: () => import('../views/CustomizeAddition.vue'),
  },
  {
    path: '/customAddition',
    name: 'customAddition',
    component: customAddition,
  },
  {
    path: '/CustomizeAddition/CustomizeAdditionStep2/:id',
    name: 'CustomizeAdditionStep2',
    component: () => import('../views/CustomizeAdditionStep2.vue'),
  },
  {
    path: '/bestseller',
    name: 'bestSeller',
    component: () => import('../views/BestSeller/bestSeller.vue'),
  },
  {
    path: '/customizeReplacement',
    name: 'CustomizeReplacement',
    component: () => import('../views/CustomizeReplacement.vue'),
  },
  {
    path: '/customReplacement',
    name: 'customReplacement',
    component: customReplacement,
  },
  {
    path: '/CustomizeReplacement/CustomizeReplacementStep2/:id',
    name: 'CustomizeReplacementStep2',
    component: () => import('../views/CustomizeReplacementStep2.vue'),
  },

  //iframe routes
  {
    path: '/iframe/vendors',
    name: 'IframeVendorsList',
    component: () => import('../views/Iframe/Vendors/iframeVendorsList.vue'),
  },
  {
    path: '/iframe/createVendor',
    name: 'IframeCreateVendor',
    component: () => import('../views/Iframe/Vendors/createIframeVendors.vue'),
  },
  {
    path: '/viewlatestArrivals',
    name: 'latestArrivals',
    component: () => import('../views/LatestArrivals/createLatestArrival.vue'),
  },

  // {
  //   path: '/editIframeVendor/:id',
  //   name: 'editIframeVendor',
  //   component: editIframeVendor,
  // },
];

const router = new VueRouter({
  routes,
});

export default router;
