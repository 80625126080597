import Vue from 'vue';

var numeral = require('numeral');

Vue.filter('currency', (value) => {
  if (typeof value !== 'number') {
    return value;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
  });
  let price = formatter.format(value).toString();
  let finalPrice = [price.slice(0, 1), price.slice(1)].join(' ');
  return finalPrice;
});
