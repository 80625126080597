import axios from 'axios';


const adminUrl = process.env.VUE_APP_API_URL;
const userUrl = process.env.VUE_APP_AUTH_URL;
const iframeUserUrl = process.env.VUE_APP_WL_AUTH_URL;
const apiIframeUrl = process.env.VUE_APP_WL_API_URL; 
// const uploadUrl = process.env.VUE_APP_FILE_UPLOAD_URL;

export default {
  async getVendorOrders(id, data) {
    console.log('Heree');
    return axios.post(`${adminUrl}/order/getAllVendorOrders/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  getVendorProducts(id, data) {
    return axios.post(`${adminUrl}/product/getProductListForVendor/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  getAllVendors(data) {
    return axios.post(`${userUrl}/user/getAllVendors`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  getAllIframeVendors(data) {
    return axios.post(`${iframeUserUrl}/user/iframe/getAllVendors`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createIframeUser(data) {
    // const data = {};
    return axios.post(`${iframeUserUrl}/user/iframe/createUserFromAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteIframeVendor(params) {
    return axios.delete(`${iframeUserUrl}/user/iframe/deleteUserFromAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createUser(data) {
    // const data = {};
    return axios.post(`${userUrl}/user/createUserFromAdmin/`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteVendor(params) {
    // const data = {};
    return axios.delete(`${userUrl}/user/deleteUserFromAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async syncBrandElasticData(data) {
    return axios.post(`${adminUrl}/elasticSearch/syncBrandElasticDataInBatchProcess`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async brandData(data, selectedExport){
    if(selectedExport ==='Brand'){
      return axios.post(`${apiIframeUrl}/iframe/report/downloadBrandRelatedReport`,data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      });
    }
    else if(selectedExport === 'Customisation'){
      return axios.post(`${apiIframeUrl}/iframe/report/downloadBrandCustomisationData`,data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      });
    }
    else if(selectedExport === 'Cumulative'){
      return axios.post(`${apiIframeUrl}/iframe/report/downloadCumulativeDataReport`,data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      });
    }
    else if(selectedExport === 'Assist Request'){
      return axios.post(`${apiIframeUrl}/iframe/report/downloadBrandStyleAssistData`,data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      });
    }
    else if(selectedExport === 'Wishlist Products'){
      return axios.post(`${apiIframeUrl}/iframe/report/downloadBrandWishListData`,data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      });
    }
    else{
      return "No Data"
    }
  }
};
