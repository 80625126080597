<template>
  <div class="">
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">Templates</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">
            List of Templates
            <span class="tableTotal font-15 poppins">(Total : {{ templates.length }})</span>
          </div>
          <div class="float-right d-flex mx-4">
            <div>
              <label for="select-product">Filter by Status</label>
              <select
                name=""
                id="ddlViewBy"
                class="select-color ml-4"
                v-model="selectedStatus"
                @change="getAllTemplates()"
              >
                <option v-for="status in statusArray" :value="status" v-bind:key="status">
                  <div>{{ status }}</div>
                </option>
              </select>
            </div>
          </div>
          <!-- <div class="float-right d-flex mx-4">
            <div></div>
            <div class="float-right ml-4">
              <router-link to="/createUser" class="Create-btn btn btn-success btn-small"
                >Create</router-link
              >
            </div>
          </div> -->
        </div>
      </div>
      <div class="row justify-center" align="center">
        <div class="col-md-12">
          <div class="widget">
            <div class="table-area">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-1 text-center">#</th>

                      <th class="col-md-3">Name</th>

                      <th class="col-md-2">Gender</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in templates" v-bind:key="item._id">
                      <td class="text-center">{{ index + 1 }}</td>

                      <td>
                        <div>{{ item.name }}</div>
                      </td>

                      <td>
                        <div>{{ item.gender }}</div>
                      </td>
                      <div class="d-flex">
                        <td>
                          <div class="mr-4">
                            <b-button
                              @click="moveToEditCustomizationPage(item._id)"
                              variant="primary"
                            >
                              Use Template
                            </b-button>
                          </div>
                        </td>

                        <td>
                          <div class="mr-4">
                            <b-button
                              variant="danger"
                              v-b-modal.delete-modal
                              @click="deleteTemplate(item._id)"
                            >
                              <b-icon-trash></b-icon-trash>
                            </b-button>
                          </div>
                        </td>
                      </div>
                    </tr>
                    <div></div>
                    <!-- end v-repeat -->
                  </tbody>
                </table>
              </div>
              <div
                class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                v-if="templates.length === 0"
              >
                No Data Found
              </div>

              <!-- Use text in props -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-4" v-if="templates.length >= 1">
      <v-pagination
        class="mb-2 float-center"
        v-model="page"
        :length="totalPages"
        :per-page="20"
        prev-icon="<"
        next-icon=">"
        @input="getAllTemplates(page)"
      >
      </v-pagination>
    </div>
  </div>
</template>

<script>
import customizationService from '../../services/customizationService';

export default {
  data() {
    return {
      totalPages: 1,
      page: 1,
      filter: {
        model: '',
        name: '',
      },
      templates: [
        {
          productName: 'Template 1',
          vendorName: 'Product 1',
          gender: 'Male',
        },
        {
          productName: 'Template 2',
          vendorName: 'Product 2',
          gender: 'Male',
        },
      ],
      selectedStatus: 'all',
      statusArray: ['all', 'draft', 'approve', 'publish'],
    };
  },
  created() {
    this.getAllTemplates();
  },
  methods: {
    async getAllTemplates() {
      const data = {};
      data.page = this.page;
      data.user = JSON.parse(localStorage.getItem('selectedVendor'));

      if (this.selectedStatus == 'all') {
        this.filter.customizationStatus = '';
      } else {
        this.filter.customizationStatus = this.selectedStatus;
      }
      data.filter = this.filter;
      const result = await customizationService.getAllTemplates(data);
      this.templates = result.data.data;
      this.totalPages = result.data.paginationData.totalPages;
    },

    async moveToEditCustomizationPage(id) {
      this.$router.push({
        name: 'Customization',
        query: { id: id },
      });
    },
    async deleteTemplate(id) {
      const result = await customizationService.deleteCustomization(id);
      if (result.status === 200) {
        this.$store.commit('setSnackbar', {
          content: 'Deleted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        this.$router.go();
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Create-btn {
  color: white !important;
}
.select-color {
  border: 1px solid rgb(0, 0, 0) !important;
  padding-left: 4px;
}
</style>
