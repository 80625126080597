<template>
  <div class="">
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">Orders</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">
            List of Orders
            <span v-if="orders" class="tableTotal font-14 poppins-medium"
              >(Total : {{ orders.length }})</span
            >
          </div>
          <div class="float-right d-flex mx-4">
            <div class="mr-4 select-duration">
              <v-select
                :items="exportOrders"
                v-model="exportExcel"
                append-icon="▼"
                solo
                dense
                placeholder="Select Duration"
                @change="durationSelect"
              >
              </v-select>
            </div>
            <v-btn class="mr-4" :disabled="exportFlag" @click="generateOrdersExcel">Export</v-btn>
            <div>
              <v-dialog v-model="dialogFilter" width="50%">
                <template v-slot:activator="{ on, attrs }">
                  <b-button variant="secondary" v-bind="attrs" v-on="on">
                    Filter
                    <b-icon-filter></b-icon-filter>
                  </b-button>
                </template>

                <v-card>
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <form name="details" class="formPart text-left">
                        <div>
                          <div class="form-group mx-4 pt-4" v-if="brands">
                            <label for="email"> Vendor:</label>

                            <v-select
                              prepend-icon=""
                              item-text="brandName"
                              v-model="filter.name"
                              :items="brands"
                              class="form-control"
                              append-icon="▼"
                            >
                              <template v-slot:selection="{ item }">
                                <v-row no-gutters>
                                  <v-col cols="12">
                                    <div>{{ item.brandName }}</div>
                                  </v-col>
                                </v-row>
                              </template>
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-on="on" v-bind="attrs">
                                  <v-list-item-content>
                                    <div>{{ item.brandName }}</div>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-select>
                          </div>
                        </div>

                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="name"> Status</label>
                            <v-select
                              :items="status"
                              v-model="filter.orderStatus"
                              class="pt-0"
                              append-icon="▼"
                              dense
                              solo
                            ></v-select>
                          </div>
                        </div>

                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="mobile">Code</label>
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              placeholder="Enter Vendor Code"
                              v-model="filter.identificationCode"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="mobile">Internal Order Status:</label>
                            <v-select
                              v-model="filter.internalOrderStatus"
                              :items="internalStatus"
                              label="Select Status"
                              solo
                              color="grey"
                            ></v-select>
                          </div>
                        </div>

                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="mobile">Order No</label>
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              placeholder="Enter Order No"
                              v-model="filter.number"
                            />
                          </div>
                        </div>
                        <div class="btnPart text-center py-4">
                          <button
                            @click="
                              active = true;
                              getAllOrders();
                            "
                            type="submit"
                            class="btn btn-success px-6"
                          >
                            Save
                          </button>

                          <button type="reset" class="btn btn-danger mx-2" @click="resetForm">
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center" align="center">
        <div class="col-md-12">
          <div class="widget">
            <div class="table-area">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-1 text-center">#</th>

                      <th class="col-md-2">Order No</th>

                      <th class="col-md-1">Quantity</th>

                      <th class="col-md-2">Price</th>
                      <th class="col-md-1">Order Status</th>
                      <th class="col-md-2">Customer Id</th>
                      <th class="col-md-2">Vendor</th>
                      <th class="col-md-2">Internal Status</th>
                      <th class="col-md-2">Date</th>
                      <th colspan="2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in orders" v-bind:key="item._id">
                      <td class="text-center">{{ index + 1 }}</td>

                      <td>
                        <div>{{ item.number }}</div>
                      </td>

                      <td>
                        <div>{{ item.quantity }}</div>
                      </td>

                      <td>
                        <div
                          v-if="
                            item.defaultDiscount &&
                            item.defaultDiscount > 0 &&
                            item.defaultDiscountPrice &&
                            item.defaultDiscountPrice > 0
                          "
                        >
                          {{ item.defaultDiscountPrice | currency }}
                        </div>
                        <div v-else>{{ item.price | currency }}</div>
                      </td>
                      <td>
                        <div>{{ item.orderStatus }}</div>
                      </td>
                      <td>
                        <div
                          v-if="
                            item &&
                            item.customerData &&
                            item.customerData[0] &&
                            item.customerData[0].customerId &&
                            item.customerData[0].customerId
                          "
                        >
                          {{ item.customerData[0].customerId }}
                        </div>
                      </td>
                      <td>
                        <div
                          v-if="
                            item &&
                            item.vendorData &&
                            item.vendorData[0] &&
                            item.vendorData[0].brandName &&
                            item.vendorData[0].brandName
                          "
                        >
                          {{ item.vendorData[0].brandName }}
                        </div>
                      </td>
                      <td>
                        <div>{{ item.internalOrderStatus }}</div>
                      </td>
                      <td>
                        <div>{{ item.createdAt | moment }}</div>
                      </td>

                      <!-- v-repeat -->
                      <td>
                        <div class="d-flex">
                          <!-- <div class="mr-8">
                            <router-link
                              class="btn btn-primary"
                              :to="{
                                name: 'editOrder',
                                params: { id: item._id },
                              }"
                              append
                            >
                              <b-icon-pencil class="text-white"></b-icon-pencil>
                            </router-link>
                          </div> -->

                          <div class="mr-8">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="green"
                                  :to="{
                                    name: 'editOrder',
                                    params: { id: item._id },
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                  append
                                >
                                  <b-icon-pencil class="text-white"></b-icon-pencil>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                          </div>

                          <!-- <div class="deletedModal">
                            <b-button
                              variant="danger"
                              v-b-modal.delete-modal
                              @click="deleteOrderById(item._id)"
                            >
                              <b-icon-trash></b-icon-trash>
                            </b-button>
                          </div> -->
                          <div class="mr-8">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-b-modal.delete-modal
                                  @click="deleteConfirm(item._id)"
                                >
                                  <b-icon-trash color="white"></b-icon-trash>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <div></div>
                    <!-- end v-repeat -->
                  </tbody>
                </table>
              </div>
              <div
                class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                v-if="orders.length === 0"
              >
                No Data Found
              </div>

              <!-- Use text in props -->

              <div class="text-center" v-if="orders.length >= 1">
                <v-pagination
                  class="mb-2 float-center"
                  v-model="page"
                  :length="totalPages"
                  @input="getAllOrders()"
                  :per-page="20"
                  prev-icon="<"
                  next-icon=">"
                />
              </div>
              <ConfirmDlg ref="confirm" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import orderService from '../../services/orderService';
import ConfirmDlg from '../../components/ConfirmDlg.vue';
import productService from '../../services/productService';

export default {
  components: { ConfirmDlg },
  data() {
    return {
      searchText: '',
      orders: [
        {
          name: 'User 1',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 2',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 3',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
      ],
      page: 1,
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10,
      totalPages: '',
      errors: [],
      active: false,
      filter: {
        quantity: '',
        number: '',
        fromPrice: '',
        toPrice: '',
        orderStatus: '',
        internalOrderStatus: '',
        customerId: '',
        fromDate: '',
        toDate: '',
        name: '',
      },
      brands: [],
      dialogFilter: false,
      internalStatus: [
        'Pending Approval',
        'In Production',
        'ReceivedatWarehouse',
        'QC Pass',
        'Package Ready',
        'Dispatched to Customer',
        'DeliveredtoCustomer',
        'QC Fail',
        'Dispatched to Designer',
        'Late',
        'Cancelled',
      ],
      status: ['pending', 'cancelled', 'completed', 'processing', 'shipped', 'pendingQuery'],
      exportOrders: ['Last 30 Days', 'Last 2 Months', 'Last 3 Months', 'Last 6 Months'],
      exportExcel: '',
      exportFlag: true,
    };
  },
  created() {
    this.getAllOrders();
    this.getAllBrands();
  },
  methods: {
    async getAllOrders() {
      this.dialogFilter = false;
      const data = {};
      if (this.active == true) {
        this.page = 1;
        data.page = this.page;
      } else {
        data.page = this.page;
      }
      data.filter = this.filter;
      const result = await orderService.getAllOrders(data);
      this.orders = [];
      this.orders = result.data.data;
      this.totalPages = result.data.paginationData.totalPages;
      this.active = false;
      //
      console.log('result', this.orders);
    },
    async deleteOrderById(id) {
      const result = await orderService.deleteOrder(id);
      if (result.status === 200) {
        this.$store.commit('setSnackbar', {
          content: 'Deleted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        this.$router.go();
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
      console.log('result', this.users);
    },
    async deleteConfirm(id) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.deleteOrderById(id);
        console.log('got confirmation');
      }
    },
    async getAllBrands() {
      const result = await productService.getAllBrands();

      this.brands = await result.data;
      console.log(this.brands, 'brands');
    },
    async resetForm() {
      this.filter = {
        number: '',
        orderStatus: '',
        customerId: '',
        name: '',
        internalStatus: '',
        fromDate: '',
        toDate: '',
        fromPrice: '',
        toPrice: '',
      };
      this.dialogFilter = false;
      await this.getAllOrders();
      this.$router.go();
    },

    async durationSelect() {
      if (this.exportExcel !== '') {
        this.exportFlag = false;
      }
    },
    async generateOrdersExcel() {
      const data = {};
      data.filterDays =
        (await this.exportExcel) === 'Last 30 Days'
          ? 30
          : this.exportExcel === 'Last 2 Months'
          ? 60
          : this.exportExcel === 'Last 3 Months'
          ? 90
          : this.exportExcel === 'Last 6 Months'
          ? 180
          : '';

      const result = await orderService.generateOrdersExcelData(data);
      window.open(result.data);
      console.log(result, 'export result');
    },
  },
  filters: {
    moment(date) {
      return moment(date).format('DD-MM-YYYY');
    },
  },
};
</script>

<style lang="scss">
.select {
  width: 140px;
  max-height: 60px;
  font-size: 11px;
}
.Create-btn {
  color: white !important;
}
.select-duration {
  width: 200px !important;
}
</style>
