var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('v-card',{staticClass:"mt-2 mx-4"},[_c('div',{staticClass:"panel-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"widget"},[_c('div',{staticClass:"form-elements-sec"},[_c('div',{staticClass:"form-body"},[_c('form',{staticClass:"formPart text-left",attrs:{"name":"details"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm(_vm.details)}}},[_c('div',[_c('div',{staticClass:"form-group col",class:{
                        'form-group--error': _vm.$v.details.brandName.$error,
                      }},[_c('label',{attrs:{"for":"name"}},[_vm._v("Brand Name:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.details.brandName),expression:"details.brandName",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"name","id":"name","placeholder":"Enter Brand Name"},domProps:{"value":(_vm.details.brandName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.details, "brandName", $event.target.value.trim())},function($event){return _vm.setTouch('brandName')}],"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.details.brandName.required && _vm.$v.details.brandName.$error)?_c('div',{staticClass:"validation"},[_vm._v(" Brand Name is required ")]):_vm._e()])]),_c('div',[_c('div',{staticClass:"form-group col",class:{
                        'form-group--error': _vm.$v.details.brandName.$error,
                      }},[_c('label',{attrs:{"for":"name"}},[_vm._v("Brand Url Code(Only Alphabets):")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.details.vendorUrlCode),expression:"details.vendorUrlCode",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","id":"name","placeholder":"Enter Brand Url Code","pattern":"[A-Za-z]+","title":"Please enter only alphabets with no spaces in between."},domProps:{"value":(_vm.details.vendorUrlCode)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.details, "vendorUrlCode", $event.target.value.trim())},_vm.validateInput],"blur":function($event){return _vm.$forceUpdate()}}}),(
                          !_vm.$v.details.vendorUrlCode.required && _vm.$v.details.vendorUrlCode.$error
                        )?_c('div',{staticClass:"validation"},[_vm._v(" Brand Url Code is required ")]):_vm._e()])]),_c('div',[_c('div',{staticClass:"form-group col",class:{
                        'form-group--error': _vm.$v.details.brandName.$error,
                      }},[_c('label',{attrs:{"for":"identificationCode"}},[_vm._v("Code (Min 3 characters required) : ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.details.identificationCode),expression:"details.identificationCode",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","id":"code","placeholder":"Enter Brand Code","minlength":"3","maxlength":"3","oninput":"this.value=this.value.toUpperCase()"},domProps:{"value":(_vm.details.identificationCode)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.details, "identificationCode", $event.target.value.trim())},function($event){return _vm.setTouch('identificationCode')}],"blur":function($event){return _vm.$forceUpdate()}}}),(
                          !_vm.$v.details.identificationCode.required &&
                          _vm.$v.details.identificationCode.$error
                        )?_c('div',{staticClass:"validation"},[_vm._v(" Code is required ")]):_vm._e()])]),_c('div',[_c('div',{staticClass:"form-group col",class:{
                        'form-group--error': _vm.$v.details.email.$error,
                      }},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.details.email),expression:"details.email",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"email","id":"email","placeholder":"name@gmail.com"},domProps:{"value":(_vm.details.email)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.details, "email", $event.target.value.trim())},function($event){return _vm.setTouch('email')}],"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.details.email.required && _vm.$v.details.email.$error)?_c('div',{staticClass:"validation"},[_vm._v(" Email is required ")]):_vm._e()])]),_c('div',[_c('div',{staticClass:"form-group col",class:{
                        'form-group--error': _vm.$v.details.mobile.$error,
                      }},[_c('label',{attrs:{"for":"mobile"}},[_vm._v("Mobile No:")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.details.mobile),expression:"details.mobile",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"number","id":"mobile","placeholder":"Enter Mobile No"},domProps:{"value":(_vm.details.mobile)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.details, "mobile", $event.target.value.trim())},function($event){return _vm.setTouch('mobile')}],"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.details.mobile.required && _vm.$v.details.mobile.$error)?_c('div',{staticClass:"validation"},[_vm._v(" Mobile No is required ")]):_vm._e()])]),_c('div',[_c('div',{staticClass:"form-group col",class:{
                        'form-group--error': _vm.$v.details.plan.$error,
                      }},[_c('label',{attrs:{"for":"email"}},[_vm._v("Plan:")]),_c('v-select',{staticClass:"pt-0",attrs:{"items":_vm.planTypes,"placeholder":"Select a Plan Type","append-icon":"▼","dense":"","solo":""},on:{"input":function($event){return _vm.setTouch('plan')}},model:{value:(_vm.details.plan),callback:function ($$v) {_vm.$set(_vm.details, "plan", $$v)},expression:"details.plan"}}),(!_vm.$v.details.plan.required && _vm.$v.details.email.$error)?_c('div',{staticClass:"validation"},[_vm._v(" Plan type is required ")]):_vm._e()],1)]),_c('div',{staticClass:"form-group col",class:{
                      'form-group--error': _vm.$v.details.logo.$error,
                    }},[_c('div',[_c('label',{attrs:{"for":"logo"}},[_vm._v("Brand Logo Image:")]),_c('div',{staticClass:"font-12 black--text font-weight-bold"},[_vm._v(" (376 W ✕ 240 H) ")]),_c('v-file-input',{staticClass:"form-control",attrs:{"prepend-icon":"","type":"file","id":"logo-image","placeholder":"Select Image","accept":"image/jpeg, image/png"},on:{"change":_vm.handleLogoImage}}),(!_vm.$v.details.logo.required && _vm.$v.details.logo.$error)?_c('div',{staticClass:"validation"},[_vm._v(" Brand Logo Image is required ")]):_vm._e()],1),(_vm.logoUrl)?_c('div',{staticClass:"preview-image border p-2 mt-3 ml-3"},[(_vm.loading)?_c('div',{staticClass:"loader"},[_vm._v("Loading...")]):_c('v-img',{attrs:{"src":_vm.logoUrl,"height":"230","width":"300px","contain":""}})],1):_vm._e()]),_c('div',{staticClass:"btnPart text-center my-4"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"submit","disabled":_vm.loading}},[_vm._v("Save")]),_c('button',{staticClass:"btn btn-danger mx-3",attrs:{"type":"reset"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(" Cancel ")])])])])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"heading-sec"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 column"},[_c('div',{staticClass:"heading-profile"},[_c('div',{staticClass:"font-24 mt-10 ml-4"},[_vm._v("Create New Designer")])])])])])}]

export { render, staticRenderFns }