<template>
  <div>
    <!-- Back button  if u want to use this breadcrumbs just remove the hidden class -->
    <div class="heading-sec">
      <div class="row">
        <div class="col-md-4 column">
          <div class="heading-profile">
            <div class="font-24 mt-10 ml-4">Create Products</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Top Bar Chart -->
    <v-card class="mt-2 mx-4">
      <div class="panel-content">
        <div class="row">
          <div class="col-md-12">
            <div class="widget">
              <div class="form-elements-sec">
                <div class="form-body">
                  <form
                    name="details"
                    class="formPart text-left"
                    @submit.prevent="submitForm(details)"
                  >
                    <!-- Email -->
                    <!-- <div>
                      <div class="form-group mx-4 pt-4" v-if="brands">
                        <label for="email"> Vendor:</label>

                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="name@gmail.com"
                          v-model.trim="details.email"
                          @input="setTouch('email')"
                        /> 
                        <v-select
                          prepend-icon=""
                          item-text="name"
                          v-model="details.email"
                          :items="brands"
                          class="form-control"
                          append-icon="▼"
                        >
                          <template v-slot:selection="{ item }">
                            <v-row no-gutters>
                              <v-col cols="12">
                                <div>{{ item.brandName }}</div>
                              </v-col>
                            </v-row>
                          </template>
                          <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-content>
                                <div>{{ item.brandName }}</div>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                        <div
                          class="validation"
                          v-if="!$v.details.email.email.required && $v.details.email.email.$error"
                        >
                          Email is required
                        </div>
                      </div>

                      <div class="mt-6">
                      <label for="email">Brand Names:</label>
             
                      <v-autocomplete
                       :items="brandNames"
                        label="Search here..."
                        solo
                        append-icon="▼"
                        class="solorfield pt-1">

                      </v-autocomplete>

                    </div>
                    </div> -->
                    <div class="form-group col ml-1">
                      <label for="linesheet">Product LineSheet:</label>

                      <v-file-input
                        prepend-icon=""
                        type="file"
                        id="assetsFieldHandle"
                        accept=".xlsx, .xls, .csv"
                        v-model="details.excelSheet"
                        @change="selectedFile"
                        class="form-control"
                      />
                      <div
                        class="validation"
                        v-if="!$v.details.excelSheet.required && $v.details.excelSheet.$error"
                      >
                        Excel Sheet is required
                      </div>

                      <ul v-if="this.filelist" v-cloak>
                        <div class="text-sm p-1" v-for="(file, index) in filelist" :key="index">
                          {{ file.name
                          }}<button
                            type="button"
                            @click="remove(filelist.indexOf(file))"
                            title="Remove file"
                          ></button>
                        </div>
                      </ul>

                      <div class="mt-2 mb-2" v-if="loader" align="center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </div>
                      <div class="btnPart text-center mt-6">
                        <button type="submit" class="btn btn-success" :disabled="loader">
                          Submit
                        </button>
                        <p class="typo__p" v-if="submitStatus === 'OK'">
                          Thanks for your submission!
                        </p>
                        <p class="typo__p" v-if="submitStatus === 'ERROR'">
                          Please fill the form correctly.
                        </p>

                        <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                          Cancel
                        </button>
                      </div>
                    </div>

                    <div v-if="sheetErrors" class="mx-4">
                      <div class="mt-4 mt-md-5">Linesheet Details:</div>
                      <div v-if="productsCreated" class="text-left text-green mt-2 mt-md-2">
                        {{ productsCreated }} products created successfully !
                      </div>
                      <div v-if="sheetErrors && sheetErrors.length > 0" class="text-left text-red">
                        {{ sheetErrors.length }} products failed !
                      </div>

                      <v-simple-table class="mt-3 table-section form-group col">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                class="text-left noto-serif-bold font-12 text-maroon"
                                width="80px"
                              >
                                Sr.No
                              </th>
                              <th class="text-left noto-serif-bold text-maroon font-12">
                                Error Location
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, idx) in sheetErrors" :key="idx">
                              <td>{{ idx + 1 }}</td>
                              <td class="text-red">
                                Improper Data at
                                <strong v-if="item.errorInParent[0] && item.errorInParent[0].srNo"
                                  >SR No -{{ item.errorInParent[0].srNo }}</strong
                                >
                                ;
                                <strong>
                                  column -
                                  <span
                                    v-for="(cell, idx) in item.errorInParent[0].cellDetails"
                                    :key="idx"
                                  >
                                    {{ cell
                                    }}<span
                                      v-if="
                                        item.errorInParent[0].cellDetails.length > 1 &&
                                        idx != item.errorInParent[0].cellDetails.length - 1
                                      "
                                      >,
                                    </span>
                                  </span>
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import productService from '../../services/productService';
import fileUploadService from '../../services/fileUploadService';

export default {
  delimiters: ['${', '}'],
  data() {
    return {
      details: {
        // email: {
        //   email: '',
        // },
        excelSheet: '',
      },
      submitStatus: null,
      sheetErrors: [],
      filelist: '',
      userType: 'Customer',
      uploadedUrl: '',
      brandNames: ['Lee', 'Biba', 'Pepe Jeans', 'FabIndia', 'Nike', 'Dell', 'Samsung'],
      productsCreated: 0,
      brands: [],
      fileName: '',
      file: {},
      loader: false,
      selectedObj: {},
      concatName: '',
    };
  },

  validations: {
    details: {
      // email: {
      //   email: {
      //     required,
      //     email,
      //   },
      // },
      excelSheet: {
        required,
      },
    },
  },

  methods: {
    // this method use for validation error

    async submitForm(details) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      } else {
        this.loader = true;
        await this.uploadExcelSheet();

        if (this.uploadedUrl != '') {
          const data = {};

          data.vendorId = JSON.parse(localStorage.getItem('selectedVendor'));
          data.url = this.uploadedUrl;
          data.name = this.fileName;
          const result = await productService.createProduct(data);

          if (result.status === 200) {
            this.loader = false;
            if (result.data.data == 'File already exists') {
              this.$store.commit('setSnackbar', {
                content: 'File with same name already exists!',
                icon: 'mdi-alert-circle',
                color: 'error ',
                isVisible: true,
              });
            } else if (
              result.data.error &&
              result.data.error.length &&
              result.data.error[0] &&
              result.data.error[0].errorInParent == 'Serial numbers are inappropriate in column A'
            ) {
              await this.$store.commit('setSnackbar', {
                content: `${result.data.error[0].errorInParent}`,
                icon: 'mdi-alert-circle',
                color: 'error ',
                isVisible: true,
              });
            } else {
              this.productsCreated = result.data.result.data.length;
              this.sheetErrors = result.data.error;
              console.log(result.data, 'sheet errors');
              console.log(this.sheetErrors, 'sheet errors');
              this.$store.commit('setSnackbar', {
                content: 'Submitted Successfully',
                icon: 'mdi-check-circle',
                color: 'success ',
                isVisible: true,
              });
            }
          } else {
            console.log(result.data.error, 'error here');
            this.$store.commit('setSnackbar', {
              content: 'Something went wrong !',
              icon: 'mdi-alert-circle',
              color: 'error ',
              isVisible: true,
            });
          }
        }
      }
    },
    async uploadExcelSheet() {
      // console.log('File: ', file);
      // this.fileName = file.name;
      const result = await fileUploadService.uploadFile(this.file);
      if (result.status == 200) {
        this.uploadedUrl = result.data.file;
        console.log('Sheet:  ', this.excelSheet);
      } else {
        console.log(result.data.error, 'error here');
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
    async getAllBrands() {
      const result = await productService.getAllBrands();
      this.brands = await result.data;
      console.log(this.brands, 'brands');
    },
    // this method use for cancel button
    resetForm() {
      this.details.email = '';
      this.details.excelSheet = '';
      this.sheetErrors = [];
      this.productsCreated = '';
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async selectedFile(file) {
      this.file = file;
      console.log('File: ', file);
      this.fileName = file.name;
    },
  },
  async created() {
    await this.getAllBrands();
  },
};
</script>
<style lang="scss" scoped>
.solorfield {
  width: 40% !important;
}
.validation {
  color: red;
}
.select-file-card {
  height: 80px;
  width: 320px;
  border: 1px solid #646464 !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}
#assetsFieldHandle {
  width: 0;
  height: 0;
}
.input-section {
  width: 40% !important;
}
.table-section {
  width: 100% !important;
  border: 1px solid black !important;
}
</style>
