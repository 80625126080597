<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h1>Step 3</h1>
          <div class="color-creation">
            <!-- <div class="row">
              <div class="col-md-3"><h4>Color Selection</h4></div>
              <div class="col-md-3">
                <button class="btn btn-danger btn-sm" @click="deleteColor()">Delete</button>
              </div>
              <div class="col-md-3">
                <button class="btn btn-primary btn-sm" @click="addColor()">Add Color</button>
              </div>
            </div> -->
            <div>
              <div class="row py-4">
                <div class="col-3">
                  <h4>Title</h4>
                </div>
                <div class="col-sm">
                  <h4>Input Box</h4>
                </div>
              </div>
            </div>
            <div v-for="color in colors" v-bind:key="color" class="color-obj">
              <div class="row">
                <div class="col-md-3">
                  <p class="color-name">{{ color }}</p>
                </div>
                <div align="center">
                  <div class="col-md-6" align="left">
                    <label for="customRange2" class="form-label text-left">Saturate</label>
                    <input type="range" class="form-range" min="0" max="5" id="customRange2" />
                  </div>
                  <div class="col-md-6" align="left">
                    <label for="customRange2" class="form-label">Hue-rotate</label>
                    <input type="range" class="form-range" min="0" max="5" id="customRange2" />
                  </div>
                  <div class="col-md-6" align="left">
                    <label for="customRange2" class="form-label">Brightness</label>
                    <input type="range" class="form-range" min="0" max="5" id="customRange2" />
                  </div>
                  <div class="col-md-6" align="left">
                    <label for="customRange2" class="form-label">opacity</label>
                    <input type="range" class="form-range" min="0" max="5" id="customRange2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-5">
            <div class="row">
              <div class="col-3">
                <h4>Icon</h4>
              </div>
              <div class="col-sm">
                <form class="md-form">
                  <div class="file-field">
                    <a class="btn-floating peach-gradient mt-0 float-left">
                      <i class="fas fa-paperclip" aria-hidden="true"></i>
                      <input type="file" />
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="my-5">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Color 1</th>
                  <th scope="col">Color 2</th>
                  <th scope="col">Icon</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Blue Pink</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="col-md-2">
                      <span class="embroidery-code">
                        <button class="btn btn-danger btn-sm" @click="deleteEmbroidery(embroidery)">
                          Delete
                        </button>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Pink blue</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="col-md-2">
                      <span class="embroidery-code">
                        <button class="btn btn-danger btn-sm" @click="deleteEmbroidery(embroidery)">
                          Delete
                        </button>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Blue Pink</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="col-md-2">
                      <span class="embroidery-code">
                        <button class="btn btn-danger btn-sm" @click="deleteEmbroidery(embroidery)">
                          Delete
                        </button>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Pink blue</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="col-md-2">
                      <span class="embroidery-code">
                        <button class="btn btn-danger btn-sm" @click="deleteEmbroidery(embroidery)">
                          Delete
                        </button>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <div class="embroidery-creation">
            <h4>Embroidery Selection</h4>
            <div v-for="embroidery in embroideries" v-bind:key="embroidery" class="color-obj">
              <div class="row">
                <div class="col-md-6">
                  <p class="embroidery-name">{{ embroidery }}</p>
                </div>
                <div class="col-md-2">
                  <span class="embroidery-code"
                    ><button class="btn btn-danger btn-sm" @click="deleteEmbroidery(embroidery)">
                      Delete
                    </button></span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <p class="color-name">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Add Embroidery"
                    v-model="embroiderySelection"
                  />
                </p>
              </div>
              <div class="col-md-2">
                <span class="color-code"
                  ><button class="btn btn-primary btn-sm" @click="addEmbroidery()">
                    Add
                  </button></span
                >
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div class="col-md-6">
          <div class="entire">
            <h1>Step 2</h1>
            <div class="category" v-for="category in categories" v-bind:key="category.name">
              <div class="category-name">
                {{ category.name }} <span class="model-name">({{ category.model }})</span>
              </div>
              <div v-for="layer in category.layers" v-bind:key="layer.name" class="layer">
                <div class="layer-name">
                  {{ layer.name }}
                  <span>
                    <select name="" id="" class="select-color">
                      <option value="">None</option>
                      <option v-for="color in colors" :value="color" v-bind:key="color">
                        {{ color }}
                      </option>
                    </select>
                  </span>
                </div>
                <div class="toggle" v-for="toggle in layer.toggles" v-bind:key="toggle">
                  <div class="toggle-name">
                    {{ toggle }}
                  </div>
                  <div class="images-upload">
                    <div class="body-type">
                      <div v-for="bodytype in bodytypes" v-bind:key="bodytype">
                        <div class="body-type-name">
                          <p>{{ bodytype }}:</p>
                          <div class="uploading-images">
                            <span
                              class="image-type image-upload-section"
                              v-for="imageType in imageTypes"
                              v-bind:key="imageType"
                              >{{ imageType }}
                            </span>
                            <span
                              class="embroidery-type image-upload-section"
                              v-for="embroidery in embroideries"
                              v-bind:key="embroidery"
                              >EMB: {{ embroidery }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
// @ is an alias to /src
export default {
  name: 'Colors',
  components: {},
  data() {
    return {
      colorSelection: '',
      embroiderySelection: '',
      categories: [
        {
          name: 'Kurta',
          model: 'Male',
          layers: [
            {
              name: 'Length',
              toggles: [
                'Longer - Calf Length',
                'Longer - Ankle Length',
                'Shorter - Above Knee Length',
                'Shorter - Knee Length',
              ],
            },
            {
              name: 'Sleeves',
              toggles: [
                'Strappy',
                'Sleeveless',
                'Cap Sleeves',
                'Half Sleeves',
                'Full Sleeves',
                'Off Shoulder - Sleeveless',
                'Off Shoulder - With Sleeves',
                'One Shoulder - Sleeveless',
                'One Shoulder - With Sleeves',
              ],
            },
            {
              name: 'Neck - Type',
              toggles: [
                'Low Neck - Scoop',
                'Low Neck - V',
                'Low Neck - Spade',
                'High Neck',
                'Medium Neck - Scoop',
                'Medium Neck - Spade',
                'Medium Neck - Square ',
              ],
            },
            {
              name: 'Back',
              toggles: ['Deep Back', 'Medium Back', 'High Back'],
            },
          ],
        },
        {
          name: 'Bottom',
          model: 'Male',
          layers: [
            {
              name: 'Bottoms - Type',
              toggles: [
                'Chudidaar',
                'Sharara',
                'Cigerette Pants',
                'Flared Pants',
                'Mermaid Lehenga',
                'Draped Pant/Skirt',
                'Lehenga',
                'Salwar',
                'Garara',
                'Dhoti Pants',
              ],
            },
          ],
        },
      ],
      model: ['Male', 'Female'],
      embroideries: ['Heavier', 'Lighter'],
      bodytypes: ['Front', 'Back', 'Plus Front', 'Plus Back'],
      imageTypes: ['Core', 'Shading'],
      colors: ['Color 1', 'Color 2', 'Color 3', 'Color 4', 'Color 5'],
    };
  },
  methods: {
    deleteEmbroidery(embroidery) {
      _.remove(this.embroideries, (n) => {
        const obj = n === embroidery;

        return obj;
      });
      this.$forceUpdate();
    },
    addEmbroidery() {
      if (!_.isEmpty(this.embroiderySelection)) {
        this.embroideries.push(this.embroiderySelection);
        this.embroiderySelection = '';
      }
    },
    deleteColor() {
      this.colors.pop();
      this.$forceUpdate();
    },
    addColor() {
      this.colors.push(`Color ${this.colors.length + 1}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-range {
  margin-left: 30px;
  width: 220px;
}
.entire {
  .category {
    margin-top: 80px;
    .model-name {
      font-size: 15px;
    }
    .category-name {
      font-size: 32px;
    }
    .layer {
      margin-top: 20px;
      select {
        font-size: 15px;
      }
      font-size: 26px;
      margin-left: 20px;
      .toggle {
        .toggle-name {
          margin-top: 10px;
        }
        font-size: 20px;
        margin-left: 20px;
        .images-upload {
          font-size: 16px;
          margin-left: 20px;
          .image-upload-section {
            font-size: 14px;
            margin-left: 20px;
            width: 100px;
            height: 100px;
            border: 1px solid #ccc;
            display: inline-block;
            text-align: center;
            line-height: 100px;
          }
          .image-type {
          }
          .embroidery-type {
          }
        }
      }
    }
  }
}
</style>
