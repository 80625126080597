import axios from "axios";
let fileUploadUrl = process.env["VUE_APP_FILE_UPLOAD_URL"];

export default {
  async uploadFile(file) {
    console.log("In heeeeeere");
    let formData = new FormData();
    formData.append("file", file);
    return axios.post(`${fileUploadUrl}/api/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
