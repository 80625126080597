import axios from 'axios';


const adminUrl = process.env.VUE_APP_AUTH_URL;
const adminIframeUrl = process.env.VUE_APP_WL_AUTH_URL;

const adminURl1 = process.env.VUE_APP_API_URL;
const apiIframeUrl = process.env.VUE_APP_WL_API_URL;

// const uploadUrl = process.env.VUE_APP_FILE_UPLOAD_URL;

export default {
  async getAllCustomers(data) {
    // const data = {};
    console.log('working');
    return axios.post(`${adminIframeUrl}/user/iframe/getAllCustomers`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getOneIframeCustomer(params) {
    console.log('working');
    return axios.get(`${adminIframeUrl}/user/iframe/getUser/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getOneCustomer(params) {
    console.log('working');
    return axios.get(`${adminIframeUrl}/user/iframe/getUser/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateUserData(params, data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminIframeUrl}/user/iframe/updateData/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createUser(data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminIframeUrl}/user/iframe/createUserFromAdmin/`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteUser(params) {
    console.log('working');
    // const data = {};
    return axios.delete(`${adminIframeUrl}/user/iframe/deleteData/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },

  async getAllCart(params, data) {
    // const data = {};
    console.log('working');
    return axios.post(`${apiIframeUrl}/cart/getAllCart/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getUserOrders(id, data) {
    console.log('Heree');
    return axios.post(`${apiIframeUrl}/order/getAllCustomerOrders/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getUserAllAddress(id, data) {
    console.log('Heree');
    return axios.post(`${apiIframeUrl}/iframe/address/getUserAllAddress/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },

  async getUserAllWishlist(id, data) {
    console.log('Heree');
    return axios.post(`${apiIframeUrl}/iframe/wishlist/getUserAllWishlists/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getUserAllSavedLooks(id, data) {
    console.log('Heree');
    return axios.post(`${apiIframeUrl}/iframe/look/getUserAllLooks/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },

  async getAllCustomization(data) {
    // const data = {};
    console.log('working');
    return axios.post(`${apiIframeUrl}/iframe/customization/getAllCustomization`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async resetPassword(data) {
    // const data = {};
    console.log('working');
    return axios.post(`${adminIframeUrl}/user/iframe/resetPasswordAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getUserAllStyleProfiles(data) {
    console.log('Heree style profile', data);
    return axios.post(`${apiIframeUrl}/styleQuiz/searchAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getUserAllSizeProfiles(id, data) {
    console.log('Heree');
    return axios.post(
      `${apiIframeUrl}/iframe/measurements/getUserAllMeasurementsAdmin/${id}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      }
    );
  },

  async updateUserSizeProfileData(params, data) {
    console.log('working');

    return axios.post(`${apiIframeUrl}/iframe/measurements/updateDataAdmin/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteUserSizeProfile(params) {
    console.log('working');
    // const data = {};
    return axios.delete(`${apiIframeUrl}/iframe/measurements/deleteData/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },

  //iframe

  async updateIFrameUserData(params, data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminIframeUrl}/user/iframe/updateData/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async exportUserData(data){
    return axios.post(`${apiIframeUrl}/iframe/report/downloadUserRelatedReport`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
};
