<template>
  <div class="">
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">List of Designers</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">
            List of Designers
            <span class="tableTotal font-15 poppins">(Total : {{ vendors.length }})</span>
          </div>
          <div class="float-right d-flex mx-4">
            <div>
              <v-dialog v-model="dialogFilter" width="50%">
                <template v-slot:activator="{ on, attrs }">
                  <b-button variant="secondary" v-bind="attrs" v-on="on">
                    Filter
                    <b-icon-filter></b-icon-filter>
                  </b-button>
                </template>

                <v-card>
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <form name="details" class="formPart text-left">
                        <div>
                          <div class="form-group mx-4 pt-4" v-if="brands">
                            <label for="email"> Vendor:</label>

                            <v-select
                              prepend-icon=""
                              item-text="brandName"
                              v-model="filter.brandName"
                              :items="brands"
                              class="form-control"
                              append-icon="▼"
                            >
                              <template v-slot:selection="{ item }">
                                <v-row no-gutters>
                                  <v-col cols="12">
                                    <div>{{ item.brandName }}</div>
                                  </v-col>
                                </v-row>
                              </template>
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-on="on" v-bind="attrs">
                                  <v-list-item-content>
                                    <div>{{ item.brandName }}</div>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-select>
                          </div>
                        </div>

                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="name"> Status</label>
                            <v-select
                              :items="vendorStatus"
                              v-model="filter.userStatus"
                              class="pt-0"
                              append-icon="▼"
                              dense
                              solo
                            ></v-select>
                          </div>
                        </div>

                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="mobile">Code</label>
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              placeholder="Enter Vendor Code"
                              v-model="filter.identificationCode"
                            />
                          </div>
                        </div>
                        <div class="btnPart text-center py-4">
                          <button
                            @click="getAllVendors()"
                            type="submit"
                            class="btn btn-success px-6"
                          >
                            Save
                          </button>

                          <button type="reset" class="btn btn-danger mx-2" @click="resetForm">
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </v-card>
              </v-dialog>
            </div>
            <div class="float-right ml-4">
              <router-link to="/createVendor" class="Create-btn btn btn-success btn-small"
                >Create</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center" align="center">
        <div class="col-md-12">
          <div class="widget">
            <div class="table-area">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-2 text-center">#</th>
                      <th class="col-md-2">Name</th>
                      <th class="col-md-2">Code</th>
                      <th class="col-md-2">Status</th>
                      <th class="col-md-2">No.of Products</th>
                      <th class="text-center" colspan="2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in vendors" v-bind:key="item._id">
                      <td class="text-center">{{ index + 1 }}</td>
                      <td>
                        <div>{{ item.brandName }}</div>
                      </td>
                      <td>
                        <div>{{ item.identificationCode }}</div>
                      </td>
                      <td>
                        <div>{{ item.userStatus }}</div>
                      </td>
                      <td>
                        <div>{{ item.productCount }}</div>
                      </td>
                      <!-- v-repeat -->
                      <td class="text-center">
                        <!-- <div class="d-flex"> -->
                          <!-- <div v-if="item._id" class="mr-4">
                            <router-link
                              class="btn btn-primary"
                              :to="{
                                name: 'editVendor',
                                params: { id: item._id },
                              }"
                              append
                            >
                              <b-icon-pencil class="text-white"></b-icon-pencil>
                            </router-link>
                          </div> -->
                          <div class="mr-4">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="deleteConfirm(item._id)"
                                >
                                  <b-icon-trash color="white"></b-icon-trash>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </div>
                        <!-- </div> -->
                      </td>
                    </tr>
                    <div></div>
                    <!-- end v-repeat -->
                  </tbody>
                </table>
              </div>
              <div
                class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                v-if="vendors.length === 0"
              >
                No Data Found
              </div>
              <!-- Use text in props -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-4" v-if="vendors.length >= 1">
      <v-pagination
        class="mb-2 float-center"
        v-model="page"
        :length="totalPages"
        :per-page="20"
        prev-icon="<"
        next-icon=">"
        @input="getAllVendors(page)"
      />
    </div>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import vendorService from '../../services/vendorService';
import ConfirmDlg from '../../components/ConfirmDlg.vue';
import productService from '../../services/productService';

export default {
  components: { ConfirmDlg },
  data() {
    return {
      totalPages: 1,
      dialog: false,
      dialogFilter: false,
      searchText: '',
      vendors: [
        {
          name: 'User 1',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 2',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 3',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 4',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 5',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
      ],
      page: 1,
      formData: { limit: 10 },
      perPage: 10,
      errors: [],
      filter: {
        firstName: '',
        lastName: '',
        brandName: '',
        identificationCode: '',
        userStatus: '',
        marginPercentage: '',
      },
      vendorStatus: ['pre-onboarding', 'onboard', 'active', 'inactive'],
      brands:[]
    };
  },
  created() {
    this.getAllVendors();
    this.getAllBrands();
  },
  methods: {
    async getAllVendors() {
      this.dialogFilter = false;
      const data = {};
      data.page = this.page;
      data.filter = this.filter;
      const result = await vendorService.getAllIframeVendors(data);
      this.vendors = result.data.data;
      this.totalPages = result.data.paginationData.totalPages;
      console.log('result', this.vendors);
    },
    async deleteVendorById(id) {
      const result = await vendorService.deleteIframeVendor(id);
      if (result.status === 200) {
        this.$store.commit('setSnackbar', {
          content: 'Submitted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        this.$router.go();
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
    async deleteConfirm(id) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.deleteVendorById(id);
      }
    },
    async getAllBrands() {
      const result = await productService.getAllBrands();

      this.brands = await result.data;
    },
    async resetForm() {
      this.filter = {
        firstName: '',
        lastName: '',
        brandName: '',
        identificationCode: '',
        userStatus: '',
        marginPercentage: '',
      };
      this.dialogFilter = false;
      await this.getAllVendors();
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  width: 140px;
  max-height: 60px;
  font-size: 11px;
}
.Create-btn {
  color: white !important;
}
</style>
