<template>
  <div class="">
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">Products</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">
            List of Products
            <span
              v-if="customizations && customizations.length > 0"
              class="tableTotal font-15 poppins"
              >(Total : {{ customizations.length }})</span
            >
            <span v-else class="tableTotal font-15 poppins">(Total : {{ 0 }})</span>
          </div>
          <!-- <div class="float-right d-flex mx-4">
            <div class="filter">
              <v-select
                label="Filter by status"
                :items="statusArray"
                v-model="selectedStatus"
                @change="getAllCustomizations()"
                append-icon="▼"
              >
              </v-select>
            </div>
            <div class="float-right ml-4 mt-2">
              <router-link to="/customization" class="Create-btn btn btn-success btn-small"
                >Create</router-link
              >
            </div>
          </div> -->
          <div class="float-right d-flex mx-4">
            <div class="mt-2">
              <v-dialog v-model="filterDialog" width="50%">
                <template v-slot:activator="{ on, attrs }">
                  <b-button variant="secondary" v-bind="attrs" v-on="on">
                    Filter
                    <b-icon-filter></b-icon-filter>
                  </b-button>
                </template>

                <v-card>
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <form name="details" class="formPart text-left">
                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="name">Palettier-SKU</label>
                            <input
                              type="name"
                              class="form-control"
                              id="name"
                              placeholder="Enter Sku"
                              v-model="filter.sku"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="name">Designer-SKU</label>
                            <input
                              type="name"
                              class="form-control"
                              id="name"
                              placeholder="Enter Designer Sku"
                              v-model="filter.designerStyleCode"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="name">Product Status</label>
                            <v-select
                              v-model="filter.productStatus"
                              :items="status"
                              label="Status"
                              dense
                              solo
                              append-icon="▼"
                            ></v-select>
                          </div>
                        </div>

                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="name">Product Name</label>
                            <input
                              type="name"
                              class="form-control"
                              id="name"
                              placeholder="Enter Name"
                              v-model="filter.name"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="name">Category</label>
                            <v-select
                              v-model="filter.category"
                              :items="categoryFilter"
                              label="category"
                              dense
                              solo
                              append-icon="▼"
                            ></v-select>
                          </div>
                        </div>
                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="name">Layer</label>
                            <v-select
                              v-model="filter.layer"
                              :items="layerFilter"
                              label="layer"
                              dense
                              solo
                              append-icon="▼"
                            ></v-select>
                          </div>
                        </div>
                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="name">Toggle</label>
                            <v-select
                              v-model="filter.toggle"
                              :items="toggleFilter"
                              label="toggle"
                              dense
                              solo
                              append-icon="▼"
                            ></v-select>
                          </div>
                        </div>
                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="name"> Status</label>
                            <v-select
                              :items="statusArray"
                              v-model="filter.customizationStatus"
                              class="pt-0"
                              append-icon="▼"
                              dense
                              solo
                            ></v-select>
                          </div>
                        </div>
                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="brandName">Brand Name</label>
                            <v-select
                              prepend-icon=""
                              item-text="brandName"
                              v-model="filter.brandName"
                              :items="brands"
                              class="form-control"
                              append-icon="▼"
                            >
                              <template v-slot:selection="{ item }">
                                <v-row no-gutters>
                                  <v-col cols="12">
                                    <div>{{ item.brandName }}</div>
                                  </v-col>
                                </v-row>
                              </template>
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-on="on" v-bind="attrs">
                                  <v-list-item-content>
                                    <div>{{ item.brandName }}</div>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-select>
                          </div>
                        </div>

                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="name"> Gender</label>
                            <v-select
                              :items="Gender"
                              v-model="filter.model"
                              class="pt-0"
                              append-icon="▼"
                              dense
                              solo
                            ></v-select>
                          </div>
                        </div>

                        <div class="btnPart text-center py-4">
                          <button
                            @click="
                              active = true;
                              filterProducts();
                            "
                            type="submit"
                            class="btn btn-success px-6"
                          >
                            Save
                          </button>

                          <button
                            type="reset"
                            class="btn btn-danger mx-2"
                            v-on:click="
                              dialog = false;
                              resetForm();
                            "
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </v-card>
              </v-dialog>
            </div>
            <div class="float-right ml-4 mt-2">
              <router-link to="/customization" class="Create-btn btn btn-success btn-small"
                >Create</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center" align="center">
        <div class="col-md-12">
          <div class="widget">
            <div class="table-area">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-1 text-center">#</th>
                      <th class="col-md-3">Palettier Sku Code</th>
                      <th class="col-md-3">Designer Sku Code</th>
                      <th class="col-md-3">Product Name</th>
                      <th class="col-md-3">Product Status</th>
                      <th class="col-md-3">Brand Name</th>
                      <th class="col-md-2">Gender</th>
                      <th class="col-md-3">Status</th>
                      <th class="col-md-2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in customizations" v-bind:key="item._id">
                      <td class="text-center">{{ index + 1 }}</td>

                      <td>
                        <div v-if="item.product && item.product.sku && item.product.sku">
                          {{ item.product.sku }}
                        </div>
                      </td>
                      <td>
                        <div
                          v-if="
                            item.product &&
                            item.product.designerStyleCode &&
                            item.product.designerStyleCode
                          "
                        >
                          {{ item.product.designerStyleCode }}
                        </div>
                      </td>
                      <td>
                        <div>
                          {{ item.product ? item.product.name : '' }}
                        </div>
                      </td>
                      <td>
                        <div v-if="item.product && item.product.productStatus">
                          {{ item.product.productStatus }}
                        </div>
                      </td>
                      <td>
                        <div>
                          {{
                            item &&
                            item.product &&
                            item.product.vendor &&
                            item.product.vendor.brandName
                              ? item.product.vendor.brandName
                              : ''
                          }}
                        </div>
                      </td>

                      <!-- <td>
                        <div>{{ item.mobile }}</div>
                      </td> -->
                      <!-- v-repeat -->
                      <td>
                        <div>{{ item.gender }}</div>
                      </td>

                      <td>
                        <div>{{ item.customizationStatus }}</div>
                      </td>

                      <td>
                        <div class="d-flex">
                          <td>
                            <div class="mr-4">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    min-width="40px"
                                    height="40px"
                                    color="#5cb85c"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="moveToEditCustomizationPage(item._id)"
                                  >
                                    <b-icon-pencil height="100%" color="white"></b-icon-pencil>
                                  </v-btn>
                                </template>
                                <span>Edit</span>
                              </v-tooltip>
                            </div>
                          </td>
                          <td>
                            <div class="mr-4">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    min-width="40px"
                                    height="40px"
                                    color="red"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="deleteConfirm(item._id)"
                                  >
                                    <b-icon-trash color="white"></b-icon-trash>
                                  </v-btn>
                                </template>
                                <span>Delete</span>
                              </v-tooltip>
                            </div>
                          </td>
                          <td>
                            <!-- <div class="mr-4">
                              <b-button
                                @click="(dialog = true), (lastSelectedId = item._id)"
                                variant="primary"
                              >
                                Save as Template
                              </b-button>
                            </div> -->
                            <div class="mr-4">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    min-width="40px"
                                    height="40px"
                                    color="blue"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="(dialog = true), (lastSelectedId = item._id)"
                                  >
                                    <img src="@/assets/Images/file-white.svg" alt="logo" />
                                  </v-btn>
                                </template>
                                <span>Save as Template</span>
                              </v-tooltip>
                            </div>
                          </td>
                        </div>
                      </td>
                    </tr>

                    <div>
                      <v-dialog v-model="dialog" width="50%">
                        <v-card>
                          <div class="form-elements-sec">
                            <div class="form-body">
                              <form name="details" class="formPart text-left">
                                <div>
                                  <div class="form-group mx-4 pt-4">
                                    <v-text-field
                                      type="name"
                                      class="form-control"
                                      id="name"
                                      placeholder="Enter Name"
                                      v-model="templateName"
                                      label="Template name"
                                      :rules="textRule"
                                    ></v-text-field>
                                  </div>
                                </div>

                                <div class="btnPart text-center py-4">
                                  <button
                                    @click="saveAsTemplate()"
                                    type="submit"
                                    class="btn btn-success px-6"
                                  >
                                    Save
                                  </button>

                                  <button
                                    type="reset"
                                    class="btn btn-danger mx-2"
                                    v-on:click="
                                      dialog = false;
                                      resetForm();
                                    "
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </v-card>
                      </v-dialog>
                    </div>
                    <div></div>
                    <!-- end v-repeat -->
                  </tbody>
                </table>
              </div>
              <div
                class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                v-if="customizations && customizations.length === 0"
              >
                No Data Found
              </div>

              <!-- Use text in props -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-4" v-if="customizations && customizations.length >= 1">
      <v-pagination
        class="mb-2 float-center"
        v-model="page"
        :length="totalPages"
        :per-page="20"
        prev-icon="<"
        next-icon=">"
        @input="filterProducts()"
      />
      <!-- <b-pagination
        class="mb-2 float-center justify-center"
        v-model="page"
         :total-rows="totalPages"
        :per-page="recordsPerPage"
        @input="getAllUsers(page)"
      /> -->
    </div>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import productService from '../../services/productService';
import customizationService from '../../services/customizationService';
import ConfirmDlg from '../../components/ConfirmDlg.vue';
import _ from 'lodash';

export default {
  components: { ConfirmDlg },
  data() {
    return {
      searchText: '',
      customizations: [
        { productName: 'Product 1', vendorName: 'User 1', gender: 'Male' },
        { productName: 'Product 2', vendorName: 'User 2', gender: 'Male' },
      ],
      page: 1,
      // formData: { limit: 10 },
      totalCount: 0,
      recordsPerPage: 10,
      errors: [],
      totalPages: 1,
      dialog: false,
      items: ['Male', 'Female'],
      templateName: '',
      lastSelectedId: '',
      filter: {
        name: '',
        sku: '',
        productStatus: '',
        model: '',
        brandName: '',
        status: '',
        designerStyleCode: '',
        toggle: '',
        layer: '',
        category: '',
      },
      textRule: [(v) => !!v || 'Template name needed'],
      statusArray: ['draft', 'approve', 'publish'],
      status: ['draft', 'reviewed', 'active', 'deactivated'],
      categoryFilter: [],
      layerFilter: [],
      toggleFilter: [],

      filterDialog: false,
      brands: [],
      Gender: ['Male', 'Female'],
    };
  },
  created() {
    this.getAllCustomizations();
    this.getAllBrands();
    this.filterData();
  },
  methods: {
    async moveToEditCustomizationPage(id) {
      this.$router.push({
        name: 'Customization',
        query: { id: id },
      });
    },

    async saveAsTemplate() {
      const data = {};
      data.parentId = this.lastSelectedId;
      data.name = this.templateName;
      data.user = JSON.parse(localStorage.getItem('selectedVendor'));

      const result = await customizationService.saveAsTemplate(data);
      this.dialog = false;
      if (result.status === 200) {
        this.$store.commit('setSnackbar', {
          content: 'Template created successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },

    async getAllCustomizations() {
      this.filterDialog = false;
      if (this.$route.query.Filters) {
        this.filter = await JSON.parse(this.$route.query.Filters);
      }
      if (this.$route.query.page) {
        this.page = await JSON.parse(this.$route.query.page);
        // console.log(this.page, 'page-no');
      }
      const data = {};
      if (this.active == true) {
        this.page = 1;
        data.page = this.page;
      } else {
        data.page = this.page;
      }
      //data.page = this.page;
      // if (this.selectedStatus == 'all') {
      //   this.filter.customizationStatus = '';
      // } else {
      //   this.filter.customizationStatus = this.selectedStatus;
      // }
      data.filter = _.clone(this.filter);
      data.user = JSON.parse(localStorage.getItem('selectedVendor'));
      const result = await customizationService.getAllCustomizations(data);
      this.customizations = result.data.data;
      this.totalPages = result.data.paginationData.totalPages;
      this.filterDialog = false;
      this.active = false;
      //this.$router.go()
    },

    async filterProducts() {
      this.filterDialog = false;
      this.$router.push({
        path: '/customizationProducts',
        query: {
          Filters: JSON.stringify(this.filter),
          page: JSON.stringify(this.page),
        },
      });
      this.getAllCustomizations();
      //this.$router.go();
    },

    async deleteCustomizationById(id) {
      const result = await customizationService.deleteCustomization(id);
      if (result.status === 200) {
        this.$store.commit('setSnackbar', {
          content: 'Deleted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        this.$router.go();
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
    async deleteConfirm(id) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.deleteCustomizationById(id);
      }
    },
    async resetForm() {
      // this.filterDialog = false;
      let filter = {
        brandName: '',
        name: '',
        sku: '',
        customizationStatus: '',
        designerStyleCode: '',
        model: '',
        productStatus: '',
        status: '',
      };
      this.filter = filter;
      this.$router.push({
        path: '/customizationProducts',
        query: {
          Filters: JSON.stringify(this.filter),
        },
      });
      await this.getAllCustomizations();
    },
    async getAllBrands() {
      const result = await productService.getAllBrands();

      this.brands = await result.data;
    },

    async filterData() {
      const result = await customizationService.getCustomizationCategoryData();
      this.categoryFilter = result.data.uniqueCategoryNames;
      this.layerFilter = result.data.uniqueLayerNames;
      this.toggleFilter = result.data.uniqueToggleNames;
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  width: 140px;
  max-height: 60px;
  font-size: 11px;
}
.Create-btn {
  color: white !important;
}
.div_boarder {
  border: 1px solid blue;
}
.select-color {
  border: 1px solid rgb(0, 0, 0) !important;
  padding-left: 4px;
}
.filter {
  width: 150px !important;
}
</style>
