import axios from 'axios';


const adminUrl = process.env.VUE_APP_API_URL;
// const uploadUrl = process.env.VUE_APP_FILE_UPLOAD_URL;

export default {
  async getAllOrders(data) {
    // const data = {};
    console.log('working');
    return axios.post(`${adminUrl}/order/getAllOrders`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getOneOrder(params) {
    console.log('working');
    return axios.get(`${adminUrl}/order/getOrderDetailsForAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateOrderData(params, data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrl}/order/updateDataAdmin/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteOrder(params) {
    console.log('working');
    // const data = {};
    return axios.delete(`${adminUrl}/order/deleteDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
   async generateOrdersExcelData(data) {
    return axios.post(`${adminUrl}/order/generateOrderExcelData`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async sendShippedMail(data) {
    return axios.post(`${adminUrl}/order/sendShippedMail`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async generatePurchaseOrder(data) {
    return axios.post(`${adminUrl}/order/purchaseOrder`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
};
