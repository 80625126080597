<template>
  <div class="">
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">Additions</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">
            List of Additions
            <span
              v-if="customizations && customizations.length > 0"
              class="tableTotal font-15 poppins"
              >(Total : {{ customizations.length }})</span
            >
            <span v-else class="tableTotal font-15 poppins">(Total : {{ 0 }})</span>
          </div>
          <div class="float-right d-flex mx-4">
            <div>
              <label for="select-product">Filter by Status</label>
              <select
                name=""
                id="ddlViewBy"
                class="select-color ml-4 mt-2"
                v-model="selectedStatus"
                @change="getAllAdditions()"
              >
                <option v-for="status in statusArray" :value="status" v-bind:key="status">
                  <div>{{ status }}</div>
                </option>
              </select>
            </div>
            <div class="ml-4">
              <router-link to="/customizeAddition" class="Create-btn btn btn-success btn-small"
                >Create</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center" align="center">
        <div class="col-md-12">
          <div class="widget">
            <div class="table-area">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-1 text-center">#</th>
                      <th class="col-md-3">Name</th>
                      <th class="col-md-2">Gender</th>
                      <th class="col-md-3">Status</th>
                      <th class="col-md-3">No of Products</th>
                      <th class="col-md-2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in customizations" v-bind:key="item._id">
                      <td class="text-center">{{ index + 1 }}</td>
                      <!-- <td>
                          <div>{{ item.mobile }}</div>
                        </td> -->
                      <!-- v-repeat -->
                      <td>
                        <div>
                          {{ item.name ? item.name : '' }}
                        </div>
                      </td>
                      <td>
                        <div>{{ item.gender }}</div>
                      </td>

                      <td>
                        <div>{{ item.customizationStatus }}</div>
                      </td>
                      <td>
                        <div v-if="item.sku && item.sku.length > 0">
                          {{ item.sku.length }}
                        </div>
                      </td>

                      <td>
                        <div class="d-flex">
                          <td>
                            <div class="mr-4">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    min-width="40px"
                                    height="40px"
                                    color="#5cb85c"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="moveToEditCustomizationPage(item._id)"
                                  >
                                    <b-icon-pencil height="100%" color="white"></b-icon-pencil>
                                  </v-btn>
                                </template>
                                <span>Edit</span>
                              </v-tooltip>
                            </div>
                          </td>
                          <td>
                            <div class="mr-4">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    min-width="40px"
                                    height="40px"
                                    color="red"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="deleteConfirm(item._id)"
                                  >
                                    <b-icon-trash color="white"></b-icon-trash>
                                  </v-btn>
                                </template>
                                <span>Delete</span>
                              </v-tooltip>
                            </div>
                          </td>
                        </div>
                      </td>
                    </tr>
                    <div></div>
                    <!-- end v-repeat -->
                  </tbody>
                </table>
              </div>
              <div
                class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                v-if="customizations && customizations.length === 0"
              >
                No Data Found
              </div>

              <!-- Use text in props -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-4" v-if="customizations && customizations.length >= 1">
      <v-pagination
        class="mb-2 float-center"
        v-model="page"
        :length="totalPages"
        :per-page="20"
        prev-icon="<"
        next-icon=">"
        @input="getAllAdditions()"
      />
      <!-- <b-pagination
          class="mb-2 float-center justify-center"
          v-model="page"
           :total-rows="totalPages"
          :per-page="recordsPerPage"
          @input="getAllUsers(page)"
        /> -->
    </div>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import productService from '../../services/productService';
import customizationService from '../../services/customizationService';
import ConfirmDlg from '../../components/ConfirmDlg.vue';
import _ from 'lodash';

export default {
  components: { ConfirmDlg },
  data() {
    return {
      searchText: '',
      customizations: [
        { productName: 'Product 1', vendorName: 'User 1', gender: 'Male' },
        { productName: 'Product 2', vendorName: 'User 2', gender: 'Male' },
      ],
      page: 1,
      // formData: { limit: 10 },
      totalCount: 0,
      recordsPerPage: 10,
      errors: [],
      totalPages: 1,
      dialog: false,
      items: ['Male', 'Female'],
      templateName: '',
      lastSelectedId: '',
      filter: {
        name: '',
        model: '',
        status: '',
      },
      textRule: [(v) => !!v || 'Template name needed'],
      selectedStatus: 'all',
      statusArray: ['all', 'draft', 'approve', 'publish'],
      status: ['draft', 'reviewed', 'active', 'deactivated'],

      filterDialog: false,
      brands: [],
      Gender: ['Male', 'Female'],
    };
  },
  created() {
    this.getAllCustomizations();
    this.getAllAdditions();
    // this.getAllBrands();
  },
  methods: {
    async getAllAdditions() {
      const data = {};
      data.page = this.page;
      data.user = JSON.parse(localStorage.getItem('selectedVendor'));

      if (this.selectedStatus == 'all') {
        this.filter.customizationStatus = '';
      } else {
        this.filter.customizationStatus = this.selectedStatus;
      }
      data.filter = this.filter;
      const result = await customizationService.getAllCustomizeAdditions(data);
      this.customizations = result.data.data;
      this.totalPages = result.data.paginationData.totalPages;
    },
    async moveToEditCustomizationPage(id) {
      this.$router.push({
        name: 'CustomizeAddition',
        query: { id: id },
      });
    },
    async getAllCustomizations() {
      this.filterDialog = false;
      if (this.$route.query.Filters) {
        this.filter = await JSON.parse(this.$route.query.Filters);
      }
      if (this.$route.query.page) {
        this.page = await JSON.parse(this.$route.query.page);
        // console.log(this.page, 'page-no');
      }
      const data = {};
      if (this.active == true) {
        this.page = 1;
        data.page = this.page;
      } else {
        data.page = this.page;
      }
      //data.page = this.page;
      // if (this.selectedStatus == 'all') {
      //   this.filter.customizationStatus = '';
      // } else {
      //   this.filter.customizationStatus = this.selectedStatus;
      // }
      data.user = JSON.parse(localStorage.getItem('selectedVendor'));

      data.filter = _.clone(this.filter);
      const result = await customizationService.getAllCustomizeAdditions(data);
      this.customizations = result.data.data;
      this.totalPages = result.data.paginationData.totalPages;
      this.filterDialog = false;
      this.active = false;
      //this.$router.go()
    },

    async deleteCustomizationById(id) {
      const result = await customizationService.deleteCustomizeAddition(id);
      if (result.status === 200) {
        this.$store.commit('setSnackbar', {
          content: 'Deleted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        this.$router.go();
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
    async deleteConfirm(id) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.deleteCustomizationById(id);
      }
    },
    async resetForm() {
      // this.filterDialog = false;
      let filter = {
        name: '',
        customizationStatus: '',
        model: '',
        status: '',
      };
      this.filter = filter;
      this.$router.push({
        path: '/customAddition',
        query: {
          Filters: JSON.stringify(this.filter),
        },
      });
      await this.getAllCustomizations();
    },
    // async getAllBrands() {
    //   const result = await productService.getAllBrands();

    //   this.brands = await result.data;
    // },
  },
};
</script>

<style lang="scss" scoped>
.select {
  width: 140px;
  max-height: 60px;
  font-size: 11px;
}
.Create-btn {
  color: white !important;
}
.div_boarder {
  border: 1px solid blue;
}
.select-color {
  border: 1px solid rgb(0, 0, 0) !important;
  padding-left: 4px;
}
.filter {
  width: 150px !important;
}
</style>
