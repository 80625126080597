import _ from 'lodash';
import axios from 'axios';
import { v4 as uuid_v4 } from 'uuid';

const apiUrl = process.env.VUE_APP_API_URL;
const apiIframeUrl = process.env.VUE_APP_WL_API_URL;

const objDefault = {
  categories: [
    {
      name: 'Core',
      sequence: 1,
      show: false,
      isEdit: false,
      isLayerRemovable: true,
      price: 100,
      id: '6dafaefe-1bcb-478e-a293-69d1b851e2f5',

      layers: [
        {
          id: '6dafaefe-1bvb-478e-a293-69d1b851e2f5',
          name: 'Color',
          sequence: 1,
          typeOfLayer: 'Color',
          show: false,
          isEdit: false,
          isLayerRemovable: true,
          showInNavigation: true,
          price: 100,
          toggles: [
            {
              name: 'Lilac',
              sequence: 1,
              isEdit: false,
              toggleImageBody: [
                { name: 'Normal Front', image: 'IMG VALUE' },
                { name: 'Normal Back', image: 'IMG VALUE' },
                { name: 'Plus Front', image: 'IMG VALUE' },
                { name: 'Plus Back', image: 'IMG VALUE' },
              ],
              embroidery: [
                { name: 'NormalFront', image: 'IMG VALUE' },
                { name: 'Normal Back', image: 'IMG VALUE' },
                { name: 'Plus Front', image: 'IMG VALUE' },
                { name: 'Plus Back', image: 'IMG VALUE' },
              ],
              price: 100,
              active: true,
            },
            { name: 'Pink', sequence: 2 },
            { name: 'Rust', sequence: 3 },
            { name: 'Yellow', sequence: 4 },
          ],
          active: true,
          color: 1,
        },
        {
          name: 'Emboidery',
          sequence: 2,
          toggles: [
            { name: 'Pearl', sequence: 1 },
            { name: 'Antique Gold', sequence: 2 },
          ],
          typeOfLayer: 'Image',
          show: false,
        },
        {
          name: 'Skin Tone',
          sequence: 3,
          toggles: [
            { name: 'Skin Tone 1', sequence: 1 },
            { name: 'Skin Tone 2', sequence: 2 },
            { name: 'Skin Tone 3', sequence: 3 },
            { name: 'Skin Tone 4', sequence: 4 },
            { name: 'Skin Tone 5', sequence: 5 },
          ],
          typeOfLayer: 'Image',
          show: false,
        },
      ],
    },
    {
      name: 'Kurta',
      sequence: 2,
      layers: [
        {
          name: 'Length',
          sequence: 1,
          toggles: [
            { name: 'Length 1', sequence: 1 },
            { name: 'Length 2', sequence: 2 },
            { name: 'Length 3', sequence: 3 },
          ],
          typeOfLayer: 'Image',
          show: true,
        },
        {
          name: 'Neck / Back',
          sequence: 2,
          toggles: [
            { name: 'Keyhole', sequence: 1 },
            { name: 'Sweetheart', sequence: 2 },
            { name: 'High', sequence: 3 },
          ],
          typeOfLayer: 'Image',
          show: true,
        },
        {
          name: 'Sleeves',
          sequence: 3,
          toggles: [
            { name: 'Half Sleeves', sequence: 1 },
            { name: 'No Sleeves', sequence: 2 },
          ],
          typeOfLayer: 'Image',
          show: true,
        },
      ],
      show: false,
    },
    {
      name: 'Dupatta',
      sequence: 3,
      layers: [
        {
          name: 'Dupatta',
          sequence: 1,
          toggles: [
            { name: 'Hide', sequence: 1 },
            { name: 'Show', sequence: 2 },
          ],
          typeOfLayer: 'Image',
          show: true,
        },
      ],
      show: false,
    },
    {
      name: 'Bottom',
      sequence: 4,
      layers: [
        {
          name: 'Style',
          sequence: 1,
          toggles: [
            { name: 'Sharara', sequence: 1 },
            { name: 'Chudiar', sequence: 2 },
          ],
          typeOfLayer: 'Image',
          show: true,
        },
      ],
      show: false,
    },
  ],
  createCategory() {
    objDefault.categories.push({
      name: '',
      sequence: objDefault.categories.length + 1,
      id: uuid_v4(),
      layers: [],
    });
  },
  deleteCategory(category) {
    _.remove(objDefault.categories, (n) => {
      const obj = n === category;
      return obj;
    });
    this.$forceUpdate();
  },
  createLayer(category) {
    category.layers.push({
      name: '',
      sequence: category.layers.length + 1,
      toggles: [],
      typeOfLayer: 'Image',
      id: uuid_v4(),
    });
  },
  deleteLayer(category, layer) {
    _.remove(category.layers, (n) => {
      const obj = n === layer;
      return obj;
    });
    this.$forceUpdate();
  },
  getCustomizationProduct(id) {
    return axios.get(`${apiIframeUrl}/iframe/customization/getProductCustomizationData/${id}`, {
      headers: {
        Authorization: localStorage.accessToken,
        'Content-Type': 'application/json',
      },
    });
  },
  getAllCustomizations(data) {
    return axios.post(`${apiIframeUrl}/iframe/customization/getAllCustomizationAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },

  saveAsTemplate(data) {
    return axios.post(`${apiIframeUrl}/iframe/customization/createTemplateAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteCustomization(params) {
    return axios.delete(`${apiIframeUrl}/iframe/customization/deleteDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },

  async getAllTemplates(data) {
    return axios.post(`${apiIframeUrl}/iframe/customization/getAllTemplatesAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },

  getOneAdminSku(data) {
    return axios.post(`${apiIframeUrl}/iframe/product/getOneAdminSku`, data, {
      headers: {
        Authorization: localStorage.accessToken,
        'Content-Type': 'application/json',
      },
    });
  },

  getOneAdmin(id) {
    return axios.get(`${apiIframeUrl}/iframe/product/getOneAdmin/${id}`, {
      headers: {
        Authorization: localStorage.accessToken,
        'Content-Type': 'application/json',
      },
    });
  },
  async fetchImageFromBox(data) {
    return axios.post(`${apiIframeUrl}/iframe/box/listFolderItems`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },

  async deleteCustomizationColorFromProductPrimaryColor(data) {
    return axios.post(
      `${apiIframeUrl}/iframe/customization/deleteCustomizationColorFromProductPrimaryColor`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      }
    );
  },
  // customAddition
  getAllCustomizeAdditions(data) {
    return axios.post(`${apiIframeUrl}/iframe/customization/searchAdminAdditions`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteCustomizeAddition(params) {
    return axios.delete(`${apiIframeUrl}/iframe/customization/deleteAdditionDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },

  // customReplacement
  getAllCustomizeReplacements(data) {
    return axios.post(`${apiIframeUrl}/iframe/customization/searchAdminReplacement`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteCustomizeReplacement(params) {
    return axios.delete(
      `${apiIframeUrl}/iframe/customization/deleteReplacementDataAdmin/${params}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      }
    );
  },
  async getCustomizationCategoryData(data) {
    return axios.post(`${apiIframeUrl}/iframe/customization/getCustomizationCategoryData`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async fetchImageFromGoogleDrive(data) {
    return axios.post(
      `${apiIframeUrl}/iframe/googledrive/driveToAWSUrlWithFolderAndFileName`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      }
    );
  },
};
export default objDefault;
